import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const cofinsOptionsConst: InterfaceSelectOptionGroup[] = [
  {
    value: '01',
    label:
      '01 - Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
  },
  {
    value: '02',
    label:
      '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))',
  },
  {
    value: '03',
    label:
      '03 - Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
  },
  {
    value: '04',
    label: '04 - Operação Tributável (tributação monofásica (alíquota zero))',
  },
  { value: '05', label: '05 - Operação Tributável (Substituição Tributária)' },
  { value: '06', label: '06 - Operação Tributável (alíquota zero)' },
  { value: '07', label: '07 - Operação Isenta da Contribuição' },
  { value: '08', label: '08 - Operação Sem Incidência da Contribuição' },
  { value: '09', label: '09 - Operação com Suspensão da Contribuição' },
  { value: '49', label: '49 - Outras Operações de Saída' },
  { value: '99', label: '99 - Outras Operações' },
];

export default cofinsOptionsConst;
