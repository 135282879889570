import { Stack } from '@tiendanube/components';
import classNames from 'classnames';

import Header from './Header';

import './Page.scss';

interface PageProps {
  header: React.ReactNode;
  children: React.ReactNode;
}

function Page({ header, children }: PageProps) {
  const classStratusPageContent = classNames('stratus--page-content-container');

  return (
    <>
      <div className={classStratusPageContent}>
        {header}
        <div className="stratus--page-content">
          <Stack column align="stretch" spacing="tight">
            {children}
          </Stack>
        </div>
      </div>
    </>
  );
}

Page.Header = Header;

export default Page;
