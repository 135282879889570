export const queryKeys = {
  company: 'company',
  statistics: 'statistics',
  taxProfiles: 'taxProfiles',
  nfes: 'nfes',
  nfeOrderValidation: 'nfeOrderValidation',
  user: 'user',
  ordersKey: 'ordersKey',
  orders: 'orders',
};
