import {
  DataList,
  Label,
  Link,
  Popover,
  Spinner,
  Stack,
  Text,
} from '@tiendanube/components';
import { CheckIcon, FlagIcon } from '@tiendanube/icons';
import { goTo } from '@tiendanube/nexo/helpers';
import { LabelStatus } from '../../../../../stratus/components';
import { useOrders } from '../../../../../stratus/context';
import { IOrderAPI } from '../../../../../stratus/interfaces';
import { nexoClient } from '../../../../../stratus/services';
import { formatDateUtil } from '../../../../../stratus/utils';

const { Row, Cell } = DataList;

function OrdersListMobile() {
  const { data: orders, loadingNfeValidationIds, nfeValidations } = useOrders();

  const renderTag = (order: IOrderAPI) => {
    const isLoadingNfeValidation = loadingNfeValidationIds?.includes(
      order.OrderId,
    );

    if (isLoadingNfeValidation) {
      return <Spinner size="medium" appearance="primary" />;
    }

    const orderValidation = nfeValidations?.find(
      (validation) => validation.OrderId === order.OrderId,
    );

    if (!orderValidation) {
      return null;
    }

    if (orderValidation.Errors.length) {
      return (
        <Popover name="Erros">
          <Stack align="flex-start" justify="flex-start" spacing="tight" column>
            {orderValidation.Errors.map((error) => (
              <Stack.Item key={error.Type}>
                <FlagIcon />
                <Text>{error.Type}</Text>
              </Stack.Item>
            ))}
          </Stack>
        </Popover>
      );
    }

    if (orderValidation.Status === 'READY_TO_ISSUE') {
      return (
        <Label
          id="id"
          label="Pronta para gerar"
          icon={CheckIcon}
          appearance="warning"
        />
      );
    }

    return <LabelStatus status={orderValidation.Status} />;
  };

  return (
    <DataList>
      {orders?.map((order) => (
        <Row id={order.OrderId} key={order.OrderId}>
          <Cell rowTitle trimText width={65}>
            <Stack column spacing="extra-tight" align="flex-start">
              <Text bold>
                <Link
                  onClick={() => goTo(nexoClient, `/orders/${order.OrderId}`)}
                  appearance="primary"
                >{`#${order.OrderDisplayId}`}</Link>
              </Text>
              <Text>
                {order.BuyerFirstName} {order.BuyerLastName}
              </Text>
              <Text>
                {order.Items.length}{' '}
                {order.Items.length > 1 ? 'produtos' : 'produto'}
              </Text>
              {renderTag(order)}
            </Stack>
          </Cell>
          <Cell width={35}>
            <Stack column align="flex-end" spacing="extra-tight">
              <Text>{formatDateUtil(order.DateOrdered, 'dd MMM')}</Text>
              <Text>
                {order.AmountTotal.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </Stack>
          </Cell>
        </Row>
      ))}
    </DataList>
  );
}

export default OrdersListMobile;
