import { useEffect, useMemo, useState } from 'react';
import {
  Card,
  Input,
  InterfaceNameValue,
  Select,
  Stack,
  Text,
} from '@tiendanube/components';
import { CancelAndSaveButtons } from '..';
import { ICompanyAPI, ITaxForm } from '../../interfaces';
import { TaxProfileTable } from './components';
import { taxSchema } from '../../schemas';
import { companyDAO } from '../../services';
import { loggerUtil } from '../../utils';
import { useToast } from '../../context';
import {
  ambienteOptionsConst,
  regimeEspecialOptionsConst,
  regimeTributarioOptionsConst,
  simplesOptionsConst,
} from '../../constants';

interface TaxCardProps {
  defaultErrors?: Record<string, string>;
  isCollapsible?: boolean;
  isOpenByDefault?: boolean;
  isEvironmentVisible?: boolean;
  isProfilesTableVisible?: boolean;
  company?: ICompanyAPI;
  updateTax?: (tax: ITaxForm) => Promise<ICompanyAPI>;
  onChange?: (data: InterfaceNameValue) => void;
}

function TaxCard({
  defaultErrors,
  isOpenByDefault,
  isCollapsible = true,
  isEvironmentVisible = true,
  isProfilesTableVisible = true,
  company,
  updateTax,
  onChange,
}: TaxCardProps) {
  const { showError, showApiError, showSuccess } = useToast();
  const initialValues = useMemo<ITaxForm>(
    () => companyDAO.taxToForm(company),
    [company],
  );

  const [errors, setErrors] = useState<Record<string, string>>(
    defaultErrors ?? {},
  );
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<ITaxForm>(initialValues);

  useEffect(() => {
    if (defaultErrors) {
      setErrors(defaultErrors);
    }
  }, [defaultErrors]);

  const handleSave = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await taxSchema.safeParseAsync(data);
      if (parsedData.success) {
        await updateTax?.(parsedData.data as ITaxForm);
        showSuccess('Dados atualizados com sucesso');
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        showError('Erro ao salvar os dados, confira os campos novamente');
      }
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Falha ao atualizar os dados fiscais, tente novamente mais tarde',
      );
    }

    setLoading(false);
  };

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
    onChange?.({ name, value });
  };

  return (
    <Card
      title="Dados Fiscais Básicos"
      isCollapsible={isCollapsible}
      isOpenByDefault={isOpenByDefault}
    >
      <Stack column align="stretch">
        <Text appearance="danger" size="highlight">
          Emissor de NFe disponivel apenas para o regime Simples Nacional
        </Text>
        <Stack>
          <Stack.Item fill>
            <Select
              name="regimeTributario"
              label="Regime Tributário"
              placeholder="Selecione uma opção"
              options={regimeTributarioOptionsConst}
              onChange={handleChange}
              value={data.regimeTributario}
              helpText={errors['regimeTributario']}
              // appearance={errors['regimeTributario'] ? 'danger' : 'default'}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Select
              name="simples"
              label="Optante do SIMPLES?"
              placeholder="Selecione uma opção"
              options={simplesOptionsConst}
              onChange={handleChange}
              value={data.simples}
              helpText={errors['simples']}
              // appearance={errors['simples'] ? 'danger' : 'default'}
            />
          </Stack.Item>
        </Stack>

        <Stack align="stretch">
          <Stack.Item fill>
            <Select
              name="regimeTributarioEspecial"
              label="Regime Especial"
              placeholder="Selecione uma opção"
              options={regimeEspecialOptionsConst}
              onChange={handleChange}
              value={data.regimeTributarioEspecial}
              helpText={errors['regimeTributarioEspecial']}
              // appearance={errors['regimeTributarioEspecial'] ? 'danger' : 'default'}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Stack align="stretch">
              <Stack.Item fill>
                <Input
                  name="serieNFe"
                  label="Série NFe"
                  placeholder="Série NFe"
                  value={data.serieNFe}
                  onChange={handleChange}
                  disabled={isLoading}
                  helpText={errors['serieNFe']}
                  appearance={errors['serieNFe'] ? 'danger' : 'default'}
                />
              </Stack.Item>
              <Stack.Item fill>
                <Input
                  name="numeroInicial"
                  label="Número Inicial"
                  placeholder="Número Inicial"
                  value={data.numeroInicial}
                  onChange={handleChange}
                  disabled={isLoading}
                  helpText={errors['numeroInicial']}
                  appearance={errors['numeroInicial'] ? 'danger' : 'default'}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>

        {isEvironmentVisible && (
          <Select
            name="ambiente"
            label="Ambiente"
            placeholder="Selecione uma opção"
            options={ambienteOptionsConst}
            onChange={handleChange}
            value={data.ambiente}
            helpText={errors['ambiente']}
            // appearance={errors['ambiente'] ? 'danger' : 'default'}
          />
        )}

        {!!updateTax && (
          <CancelAndSaveButtons
            saveText="Salvar"
            onCancel={() => null}
            onSave={handleSave}
            cancelText="Cancelar"
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        )}

        {isProfilesTableVisible && <TaxProfileTable />}
      </Stack>
    </Card>
  );
}

export default TaxCard;
