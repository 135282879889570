import { z } from 'zod';

const signupSchema = z.object({
  firstName: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  phone: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  email: z
    .string()
    .trim()
    .email({ message: 'Email inválido' })
    .min(1, { message: 'Esse campo é obrigatório' }),
  app: z.string().trim(),
});

export default signupSchema;
