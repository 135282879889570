//   You can switch it ⌄⌄⌄⌄⌄
const enableMockData = false;
//       You can switch it ⌄⌄⌄⌄⌄
const enableTestClientId = false;

const enableLog = process.env.NODE_ENV !== 'production';

export const appConfigData = {
  // App
  appName: 'NoovFiscal',
  defaultPartnerId: 31,
  contactEmail: 'contato@hubnoov.com.br',
  useMockData: enableMockData,
  useLogger: enableLog,
  defaultPageSize: 20,

  // API
  baseUserUrl: 'https://apiuser.allintegra.com.br',
  baseUrl: 'https://apifiscal.allintegra.com.br',
  baseOrderUrl: 'https://apicatalogv4.allintegra.com.br',
  baseBrasilAPIUrl: 'https://brasilapi.com.br/api',
  apiTimeout: 60_000,
  header: {
    nsApp: enableTestClientId ? 'NoovFiscalDevel' : 'NoovFiscal',
  },

  // Storage
  storageNuvemshopTokenKey: '@NoovFiscal/NuvemshopToken',
  storageNuvemshopConnectionId: '@NoovFiscal/ConnectionId',

  // Nexo
  nexoClientId: enableTestClientId ? '0000' : '6127',
  nexoLog: enableLog,

  // Certificate
  certificateFileType: 'application/x-pkcs12,.pfx',
  certificateFileSizeLimit: 15_728_640,

  // Image
  imageFileType: 'image/png,image/jpeg,image/jpg',
  imageFileSizeLimit: 1_048_576,

  // Sentry
  sentryDsn:
    'https://f7a30c8c17e24e83888cebf4c08bdd39@o4504953524191232.ingest.sentry.io/4504953525895168',
  sentryTracesSampleRate: 0.1,
} as const;
