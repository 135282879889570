import axios, { AxiosInstance } from 'axios';
import { appConfigData } from '../data';
import { IUserAPI, IAPIResponse, ISignupForm } from '../interfaces';
import baseAuthAxios from './baseAuthAxios';

class BaseUserAxios {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: appConfigData.baseUserUrl,
      timeout: appConfigData.apiTimeout,
      maxRedirects: 0,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.api.interceptors.request.use(
      (config) => baseAuthAxios.getInterceptorConfig(config),
      (error) => Promise.reject(error),
    );
  }

  public getUser = async () => {
    const { data } = await this.api.get<IAPIResponse<IUserAPI>>('/User');

    return data.Response;
  };

  public signup = async (userData: ISignupForm) => {
    // TODO: Check this response
    const { data } = await this.api.post<IAPIResponse<any>>(
      '/User/NSUserSignUp',
      userData,
    );

    return data;
  };
}

const baseUserAxios = new BaseUserAxios();

export default baseUserAxios;
