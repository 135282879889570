import axios, { AxiosInstance } from 'axios';
import { appConfigData } from '../data';
import { ICEPResponse, IIBGEResponse } from '../interfaces';
import { removeAccentUtil } from '../utils';

class BaseExternalAxios {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      timeout: appConfigData.apiTimeout,
    });
  }

  public getCEP = async (cep: string) => {
    const cepNumbers = cep.replace(/\D/g, '');
    const { data } = await this.api.get<ICEPResponse>(
      `${appConfigData.baseBrasilAPIUrl}/cep/v2/${cepNumbers}`,
    );
    return data;
  };

  public getIBGECode = async (
    state: string,
    city: string,
  ): Promise<string | undefined> => {
    const { data } = await this.api.get<IIBGEResponse[]>(
      `${appConfigData.baseBrasilAPIUrl}/ibge/municipios/v1/${state}?providers=dados-abertos-br,gov,wikipedia`,
    );

    const parsedCity = removeAccentUtil(city).toUpperCase();
    const ibgeCode = data.filter(
      (item) => removeAccentUtil(item.nome).toUpperCase() === parsedCity,
    );

    return ibgeCode[0]?.codigo_ibge;
  };
}

const baseExternalAxios = new BaseExternalAxios();

export default baseExternalAxios;
