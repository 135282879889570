import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const regimeEspecialOptionsConst: InterfaceSelectOptionGroup[] = [
  { label: 'MEI', value: 'MEI' },
  { label: 'ME / EPP', value: 'ME_EPP' },
  { label: 'Cooperativa', value: 'Cooperativa', disabled: true },
  {
    label: 'Sem Regime Especial',
    value: 'SemRegimeEspecial',
    disabled: true,
  },
  {
    label: 'MicroEmpresa Municipal',
    value: 'MicroEmpresaMunicipal',
    disabled: true,
  },
  { label: 'Estimativa', value: 'Estimativa', disabled: true },
  {
    label: 'Sociedade de Profissionais',
    value: 'SociedadeDeProfissionais',
    disabled: true,
  },
];

export default regimeEspecialOptionsConst;
