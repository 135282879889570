import { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { appConfigData, queryKeys } from '../../data';
import {
  INfeCreateForm,
  InvoiceRequestResult,
  IOrderAPI,
  IOrderDataForm,
  IOrderProductFiscalInfoForm,
} from '../../interfaces';
import { baseAxios, baseOrderAxios, orderDAO } from '../../services';
import { loggerUtil, mergeArrays } from '../../utils';
import { useAuthentication } from '../AuthenticationContext';
import { useCompany } from '../CompanyContext';
import { useTax } from '../TaxContext';
import { useToast } from '../ToastContext';

interface OrdersContextData {
  status: 'idle' | 'error' | 'loading' | 'success';
  data: IOrderAPI[] | undefined;
  page: number;
  pageTotal: number;
  totalItems: number;
  pageSize: number;
  hasNfeToIssue: boolean;
  nfeValidations: InvoiceRequestResult[] | undefined;
  loadingNfeValidationIds: string[];
  refetch: () => void;
  setPage: (page: number) => void;
  setPageSize: (newPageSize: number) => void;
  validateOrders: (orders: IOrderAPI[]) => Promise<void>;
  patchProductFiscalInfo: (
    productId: string,
    productChildId: string,
    values: IOrderProductFiscalInfoForm,
  ) => Promise<any>;
  openSalesPage: () => void;
}

const OrdersContext = createContext<OrdersContextData>({} as OrdersContextData);

interface OrdersProviderProps {
  children: React.ReactNode;
}

export function OrdersProvider({ children }: OrdersProviderProps) {
  const { showError, showApiError } = useToast();
  const { storeId } = useAuthentication();
  const { hasCompany } = useCompany();
  const { data: taxProfiles } = useTax();

  const [hasNfeToIssue] = useState(false);
  const [loadingNfeValidationIds, setLoadingNfeValidationIds] = useState<
    string[]
  >([]);
  const [nfeValidations, setNfeValidations] = useState<InvoiceRequestResult[]>(
    [],
  );
  const [pageSize, setPageSize] = useState<number>(
    appConfigData.defaultPageSize,
  );
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageTotal = Math.ceil(totalItems / pageSize);

  const { data: connection } = useQuery(
    [queryKeys.ordersKey, storeId],
    () => baseOrderAxios.getUserConnections(),
    {
      enabled: hasCompany,
      keepPreviousData: true,
    },
  );

  const validateOrders = async (orders: IOrderAPI[]) => {
    if (orders.length) {
      const orderIds = orders.map((order) => order.OrderId);
      setLoadingNfeValidationIds(orderIds);

      const taxProfile = taxProfiles?.[0];

      if (!taxProfile) {
        showError(
          'Não foi possivel validar os pedidos. Você precisa criar primeiro um perfil fiscal',
        );
        setLoadingNfeValidationIds([]);
        return;
      }

      try {
        const ordersToInvoice: IOrderDataForm[] = orders.map((order) => ({
          Order: orderDAO.orderToOrderData(order),
          OrderId: order.OrderId,
        }));

        const ordersToValidate: INfeCreateForm = {
          Configuration: taxProfile,
          OrdersToInvoice: ordersToInvoice,
        };

        const validation = await baseAxios.validateNFesOrders(ordersToValidate);

        setNfeValidations((validations) =>
          mergeArrays(validations, validation.InvoicesResults, 'OrderId'),
        );
        // TODO: Improve the code below
        // setHasNfeToIssue(
        //   validation.InvoicesResults.some(
        //     (result) => result.Status === 'READY_TO_ISSUE',
        //   ),
        // );
      } catch (error) {
        loggerUtil(error);
        showApiError(
          error,
          'Não foi possivel validar os pedidos, tente novamente mais tarde',
        );
      }
    }

    setLoadingNfeValidationIds([]);
  };

  const openSalesPage = () => {
    window.open('https://www.noovfiscal.com.br/');
  };

  const { data, status, refetch } = useQuery(
    [queryKeys.orders, page, pageSize, storeId],
    () => {
      return baseOrderAxios.getNSOrders(
        pageSize,
        page,
        connection?.ConnectionId ?? '',
      );
    },
    {
      enabled: !!connection?.ConnectionId,
      onSuccess: async (data) => {
        setTotalItems(data.Total ?? data?.Response?.length ?? 0);

        validateOrders(data.Response ?? []);
      },
    },
  );

  function patchProductFiscalInfo(
    productId: string,
    productChildId: string,
    values: IOrderProductFiscalInfoForm,
  ) {
    return baseOrderAxios.patchProductFiscalInfo(
      connection?.ConnectionId ?? '',
      productId,
      productChildId,
      values,
    );
  }

  return (
    <OrdersContext.Provider
      value={{
        data: data?.Response,
        status,
        page,
        pageTotal,
        totalItems,
        pageSize,
        loadingNfeValidationIds,
        nfeValidations,
        hasNfeToIssue,
        refetch,
        setPage,
        setPageSize,
        patchProductFiscalInfo,
        validateOrders,
        openSalesPage,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export function useOrders(): OrdersContextData {
  const context = useContext(OrdersContext);

  if (!context) {
    throw new Error('useOrders must be used within an OrdersProvider');
  }

  return context;
}
