import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { queryKeys } from '../../data';
import { IStatisticsAPI } from '../../interfaces';
import { baseAxios } from '../../services';
import { useAuthentication } from '../AuthenticationContext';
import { useCompany } from '../CompanyContext';

interface StatisticsContextData {
  data: IStatisticsAPI | undefined;
  status: 'error' | 'idle' | 'loading' | 'success';
  refetch: () => void;
}

const StatisticsContext = createContext<StatisticsContextData>(
  {} as StatisticsContextData,
);

interface StatisticsProviderProps {
  children: React.ReactNode;
}

export function StatisticsProvider({ children }: StatisticsProviderProps) {
  const { storeId } = useAuthentication();
  const { hasCompany } = useCompany();

  const { data, status, refetch } = useQuery(
    [queryKeys.statistics, storeId],
    baseAxios.getStatistics,
    { enabled: hasCompany },
  );

  return (
    <StatisticsContext.Provider value={{ data, status, refetch }}>
      {children}
    </StatisticsContext.Provider>
  );
}

export function useStatistics(): StatisticsContextData {
  const context = useContext(StatisticsContext);

  if (!context) {
    throw new Error('useStatistics must be used within an StatisticsProvider');
  }

  return context;
}
