import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const regimeTributarioOptionsConst: InterfaceSelectOptionGroup[] = [
  { label: 'Simples Nacional', value: 'SimplesNacional' },
  {
    label: 'Simples Nacional - Excesso',
    value: 'SimplesNacionalExcesso',
    disabled: true,
  },
  {
    label: 'Lucro Presumido',
    value: 'LucroPresumido',
    disabled: true,
  },
  { label: 'Lucro Real', value: 'LucroReal', disabled: true },
];

export default regimeTributarioOptionsConst;
