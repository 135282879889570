import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const cfopOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: 'x101', label: 'x101 - Venda de produção do estabelecimento' },
  {
    value: 'x102',
    label: 'x102 - Venda de mercadoria adquirida ou recebida de terceiros',
  },
  {
    value: 'x103',
    label:
      'x103 - Venda de produção do estabelecimento, efetuada fora do estabelecimento',
  },
  {
    value: 'x104',
    label:
      'x104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento',
  },
  {
    value: 'x105',
    label:
      'x105 - Venda de produção do estabelecimento que não deva por ele transitar',
  },
  {
    value: 'x106',
    label:
      'x106 - Venda de mercadoria adquirida ou recebida de terceiros, que não deva por ele transitar',
  },
  {
    value: 'x107',
    label:
      'x107 - Venda de produção do estabelecimento, destinada a não contribuinte',
  },
  {
    value: 'x108',
    label:
      'x108 - Venda de mercadoria adquirida ou recebida de terceiros, destinada a não contribuinte',
  },
  {
    value: 'x109',
    label:
      'x109 - Venda de produção do estabelecimento, destinada à Zona Franca de Manaus ou Áreas de Livre Comércio',
  },
  {
    value: 'x110',
    label:
      'x110 - Venda de mercadoria adquirida ou recebida de terceiros, destinada à Zona Franca de Manaus ou Áreas de Livre Comércio',
  },
  {
    value: 'x111',
    label:
      'x111 - Venda de produção do estabelecimento remetida anteriormente em consignação industrial',
  },
  {
    value: 'x112',
    label:
      'x112 - Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação industrial',
  },
  {
    value: 'x113',
    label:
      'x113 - Venda de produção do estabelecimento remetida anteriormente em consignação mercantil',
  },
  {
    value: 'x114',
    label:
      'x114 - Venda de mercadoria adquirida ou recebida de terceiros remetida anteriormente em consignação mercantil',
  },
  {
    value: 'x115',
    label:
      'x115 - Venda de mercadoria adquirida ou recebida de terceiros, recebida anteriormente em consignação mercantil',
  },
  {
    value: 'x116',
    label:
      'x116 - Venda de produção do estabelecimento originada de encomenda para entrega futura',
  },
  {
    value: 'x117',
    label:
      'x117 - Venda de mercadoria adquirida ou recebida de terceiros, originada de encomenda para entrega futura',
  },
  {
    value: 'x118',
    label:
      'x118 - Venda de produção do estabelecimento entregue ao destinatário por conta e ordem do adquirente originário, em venda à ordem',
  },
  {
    value: 'x119',
    label:
      'x119 - Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário por conta e ordem do adquirente (...)',
  },
  {
    value: 'x120',
    label:
      'x120 - Venda de mercadoria adquirida ou recebida de terceiros entregue ao destinatário pelo vendedor remetente (...)',
  },
  {
    value: 'x122',
    label:
      'x122 - Venda de produção do estabelecimento remetida para industrialização, por conta e ordem (...)',
  },
  {
    value: 'x123',
    label:
      'x123 - Venda de mercadoria adquirida ou recebida de terceiros remetida para industrialização (...)',
  },
];

export default cfopOptionsConst;
