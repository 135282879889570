import React from 'react';
import { InterfaceLink, Popover, Spinner, Text } from '@tiendanube/components';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';
import { useOrders } from '../../../../../../../stratus/context';
import { EditIcon, InvoiceIcon } from '@tiendanube/icons';

interface OrderCellActionsProps {
  order: IOrderAPI;
  issuingNfe: boolean;
  openModal: (order: IOrderAPI) => void;
}

function OrderCellActions({
  order,
  issuingNfe,
  openModal,
}: OrderCellActionsProps) {
  const { nfeValidations, loadingNfeValidationIds } = useOrders();

  const isLoadingNfeValidation = loadingNfeValidationIds.includes(
    order.OrderId,
  );

  if (issuingNfe) {
    return <Spinner size="medium" appearance="primary" />;
  }

  if (isLoadingNfeValidation) {
    return null;
  }

  const orderValidation = nfeValidations?.find(
    (validation) => validation.OrderId === order.OrderId,
  );

  if (!orderValidation) {
    return null;
  }

  const itens: Pick<
    InterfaceLink,
    'children' | 'appearance' | 'onClick' | 'icon'
  >[] = [];

  if (orderValidation.Errors.length) {
    itens.push({
      icon: EditIcon,
      children: <Text>Corrigir problemas</Text>,
      onClick: () => openModal(order),
    });
  }

  if (orderValidation.Status === 'APPROVED') {
    // TODO: Show download PDF and XML
  }

  if (orderValidation.Status === 'REJECTED') {
    itens.push({
      icon: InvoiceIcon,
      children: <Text>Gerar nova NFe</Text>,
      onClick: () => openModal(order),
    });
  }

  if (orderValidation.Status === 'READY_TO_ISSUE') {
    itens.push({
      icon: InvoiceIcon,
      children: <Text>Gerar NFe</Text>,
      onClick: () => openModal(order),
    });
  }

  if (!itens.length) {
    return null;
  }

  return <Popover name="" position="right" menu={itens} />;
}

export default OrderCellActions;
