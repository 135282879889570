import useWindowSize from '../useWindowSize';

interface UseResponsiveResult {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 992;

function useResponsive(): UseResponsiveResult {
  const { width } = useWindowSize();

  const isMobile = width < MOBILE_BREAKPOINT;
  const isTablet = !isMobile && width < TABLET_BREAKPOINT;

  return {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
  };
}

export default useResponsive;
