import {
  IRegimeTributario,
  IRegimeTributarioEspecial,
} from '../interfaces/ITaxForm';
import {
  IAddressForm,
  ICompanyForm,
  ICompanyAPI,
  ITaxForm,
} from '../interfaces';

class CompanyDAO {
  public companyToAPI(
    companyApi: ICompanyAPI,
    company?: ICompanyForm,
  ): ICompanyAPI {
    return {
      ...companyApi,
      Cnpj: company?.Cnpj ?? companyApi.Cnpj,
      CompanyInfo: {
        ...companyApi.CompanyInfo,
        RazaoSocial: company?.RazaoSocial ?? companyApi.CompanyInfo.RazaoSocial,
        NomeFantasia:
          company?.NomeFantasia ?? companyApi.CompanyInfo.NomeFantasia,
        InscrEstadual:
          company?.InscrEstadual ?? companyApi.CompanyInfo.InscrEstadual,
        InscrMunicipal:
          company?.InscrMunicipal ?? companyApi.CompanyInfo.InscrMunicipal,
        EmailFiscal: company?.EmailFiscal ?? companyApi.CompanyInfo.EmailFiscal,
        TelefoneFiscal: +(
          company?.TelefoneFiscal ?? companyApi.CompanyInfo.TelefoneFiscal
        ),
        TelefoneFiscalDDD: +(
          company?.TelefoneFiscalDDD ?? companyApi.CompanyInfo.TelefoneFiscalDDD
        ),
      },
    };
  }

  public companyToForm(company?: ICompanyAPI): ICompanyForm {
    return {
      Cnpj: company?.Cnpj ?? '',
      EmailFiscal: company?.CompanyInfo.EmailFiscal ?? '',
      InscrEstadual: company?.CompanyInfo.InscrEstadual ?? '',
      InscrMunicipal: company?.CompanyInfo.InscrMunicipal ?? '',
      NomeFantasia: company?.CompanyInfo.NomeFantasia ?? '',
      RazaoSocial: company?.CompanyInfo.RazaoSocial ?? '',
      TelefoneFiscalDDD:
        company?.CompanyInfo.TelefoneFiscalDDD.toString() ?? '',
      TelefoneFiscal: company?.CompanyInfo.TelefoneFiscal.toString() ?? '',
    };
  }

  public addressToAPI(
    companyApi: ICompanyAPI,
    address?: IAddressForm,
  ): ICompanyAPI {
    return {
      ...companyApi,
      CompanyInfo: {
        ...companyApi.CompanyInfo,
        Address: {
          ...companyApi.CompanyInfo.Address,
          CEP: address?.CEP ?? companyApi.CompanyInfo.Address.CEP,
          Street: address?.Street ?? companyApi.CompanyInfo.Address.Street,
          Number: +(address?.Number ?? companyApi.CompanyInfo.Address.Number),
          Complement:
            address?.Complement ?? companyApi.CompanyInfo.Address.Complement,
          Neighbourhood:
            address?.Neighbourhood ??
            companyApi.CompanyInfo.Address.Neighbourhood,
          City: address?.City ?? companyApi.CompanyInfo.Address.City,
          State: address?.State ?? companyApi.CompanyInfo.Address.State,
          CodigoIBGE: +(
            address?.CodigoIBGE ?? companyApi.CompanyInfo.Address.CodigoIBGE
          ),
        },
      },
    };
  }

  public addressToForm(company?: ICompanyAPI): IAddressForm {
    return {
      CEP: company?.CompanyInfo?.Address?.CEP ?? '',
      City: company?.CompanyInfo?.Address?.City ?? '',
      CodigoIBGE: company?.CompanyInfo?.Address?.CodigoIBGE?.toString() ?? '',
      Complement: company?.CompanyInfo?.Address?.Complement ?? '',
      Neighbourhood: company?.CompanyInfo?.Address?.Neighbourhood ?? '',
      Number: company?.CompanyInfo?.Address?.Number?.toString() ?? '',
      State: company?.CompanyInfo?.Address?.State ?? '',
      Street: company?.CompanyInfo?.Address?.Street ?? '',
    };
  }

  public taxToAPI(companyApi: ICompanyAPI, tax?: ITaxForm): ICompanyAPI {
    const RegimeTributarioValue: Record<IRegimeTributario, number> = {
      Nenhum: 0,
      SimplesNacional: 1,
      SimplesNacionalExcesso: 2,
      LucroPresumido: 3,
      LucroReal: 4,
    };

    const RegimeTributarioEspecialValue: Record<
      IRegimeTributarioEspecial,
      number
    > = {
      SemRegimeEspecial: 0,
      MicroEmpresaMunicipal: 1,
      Estimativa: 2,
      SociedadeProfissionais: 3,
      Cooperativa: 4,
      MEI: 5,
      ME_EPP: 6,
    };

    return {
      ...companyApi,
      Fiscal: {
        ...companyApi.Fiscal,
        IsSimplesNacional: tax?.regimeTributario === 'SimplesNacional',
        Producao: tax?.ambiente === 'prod',
        RegimeTributario: tax?.regimeTributario
          ? {
              Name: tax?.regimeTributario,
              Value: RegimeTributarioValue[tax.regimeTributario],
            }
          : companyApi.Fiscal.RegimeTributario,
        RegimeTributarioEspecial: tax?.regimeTributarioEspecial
          ? {
              Name: tax.regimeTributarioEspecial,
              Value:
                RegimeTributarioEspecialValue[tax.regimeTributarioEspecial],
            }
          : companyApi.Fiscal.RegimeTributarioEspecial,
        NfeNumeracao: [
          {
            InicioNumero: +(
              tax?.numeroInicial ??
              companyApi.Fiscal.NfeNumeracao[0].InicioNumero
            ),
            NfeSerie: +(
              tax?.serieNFe ?? companyApi.Fiscal.NfeNumeracao[0].NfeSerie
            ),
          },
        ],
      },
    };
  }

  public taxToForm(company?: ICompanyAPI): ITaxForm {
    return {
      ambiente: company?.Fiscal.Producao ? 'prod' : 'stg',
      numeroInicial:
        company?.Fiscal.NfeNumeracao[0].InicioNumero.toString() ?? '',
      regimeTributarioEspecial:
        company?.Fiscal.RegimeTributarioEspecial.Name ?? 'MEI',
      regimeTributario:
        company?.Fiscal.RegimeTributario.Name ?? 'SimplesNacional',
      serieNFe: company?.Fiscal.NfeNumeracao[0].NfeSerie.toString() ?? '',
      simples: company?.Fiscal.IsSimplesNacional ? 'yes' : 'no',
    };
  }
}

const companyDAO = new CompanyDAO();

export default companyDAO;
