import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import App from './App';
import {
  StatisticsProvider,
  AuthenticationProvider,
  CompanyProvider,
  NfeProvider,
  TaxProvider,
  UserProvider,
  ToastProvider,
  OrdersProvider,
} from './stratus/context';
import { queryClient } from './stratus/services';
import { appConfigData } from './stratus/data';

Sentry.init({
  dsn: appConfigData.sentryDsn,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: appConfigData.sentryTracesSampleRate,
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ToastProvider>
      <AuthenticationProvider>
        <UserProvider>
          <CompanyProvider>
            <TaxProvider>
              <StatisticsProvider>
                <NfeProvider>
                  <OrdersProvider>
                    <App />
                  </OrdersProvider>
                </NfeProvider>
              </StatisticsProvider>
            </TaxProvider>
          </CompanyProvider>
        </UserProvider>
      </AuthenticationProvider>
    </ToastProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
