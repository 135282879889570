import {
  Button,
  Card,
  Input,
  InterfaceNameValue,
  Stack,
  Text,
} from '@tiendanube/components';
import { appConfigData } from '../../stratus/data';
import { noovfiscalLogoBlack } from '../../stratus/assets';
import { useState } from 'react';
import { ISignupForm } from '../../stratus/interfaces';
import { signupSchema } from '../../stratus/schemas';
import { useHistory } from 'react-router-dom';
import { loggerUtil } from '../../stratus/utils';
import { useToast, useUser } from '../../stratus/context';

function SignupPage() {
  const history = useHistory();
  const { signup } = useUser();
  const { showError, showApiError } = useToast();
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<ISignupForm>({
    firstName: '',
    phone: '',
    email: '',
    app: 'NoovFiscal',
  });

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await signupSchema.safeParseAsync(data);
      if (parsedData.success) {
        await signup(parsedData.data);
        history.replace('/');
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        setLoading(false);
        showError('Erro ao salvar os dados, confira os campos novamente');
      }
    } catch (error) {
      showApiError(
        error,
        'Ocorreu um erro ao criar sua conta, tente novamente mais tarde',
      );
      loggerUtil(error);
      setLoading(false);
    }
  };

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div className="nimbus--full-screen-center-container">
      <Stack column spacing="base">
        <img src={noovfiscalLogoBlack} className="nimbus--app-logo" />
        <div className="nimbus--inner-container">
          <Card title="">
            <Stack column align="stretch">
              <Text bold textAlign="center">
                Olá! Para ativarmos o app do {appConfigData.appName} precisamos
                de mais alguns dados
              </Text>

              <Input
                name="firstName"
                label="Nome Completo"
                placeholder="João da Silva"
                value={data.firstName}
                appearance={errors['firstName'] ? 'danger' : 'default'}
                helpText={errors['firstName']}
                onChange={handleChange}
                disabled={isLoading}
              />

              <Input
                name="email"
                label="E-mail de contato"
                placeholder="joao@meuemail.com.br"
                value={data.email}
                appearance={errors['email'] ? 'danger' : 'default'}
                helpText={errors['email']}
                onChange={handleChange}
                disabled={isLoading}
              />

              <Input
                name="phone"
                label="Telefone"
                placeholder="(11) 99999-9999"
                value={data.phone}
                appearance={errors['phone'] ? 'danger' : 'default'}
                helpText={errors['phone']}
                onChange={handleChange}
                disabled={isLoading}
              />

              <Stack spacing="base" justify="center">
                <Button
                  appearance="primary"
                  onClick={handleSubmit}
                  spinner={isLoading}
                >
                  Continuar
                </Button>
              </Stack>
            </Stack>
          </Card>
        </div>
      </Stack>
    </div>
  );
}

export default SignupPage;
