import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const csosnOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: '101', label: '101 - Tributada com permissão de crédito' },
  { value: '102', label: '102 - Tributada sem permissão de crédito' },
  { value: '103', label: '103 - Isenção do ICMS para faixa de receita bruta' },
  {
    value: '201',
    label:
      '201 - Tributada com permissão de crédito e com cobrança do ICMS por ST',
  },
  {
    value: '202',
    label:
      '202 - Tributada sem permissão de crédito e com cobrança do ICMS por ST',
  },
  {
    value: '203',
    label:
      '203 - Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por ST',
  },
  { value: '300', label: '300 - Imune' },
  { value: '400', label: '400 - Não tributada pelo Simples Nacional' },
  {
    value: '500',
    label:
      '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
  },
  { value: '900', label: '900 - Outro' },
];

export default csosnOptionsConst;
