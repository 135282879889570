import React from 'react';
import { Link } from '@tiendanube/components';
import { goTo } from '@tiendanube/nexo/helpers';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';
import { nexoClient } from '../../../../../../../stratus/services';

interface OrderCellIdProps {
  order: IOrderAPI;
}

function OrderCellId({ order }: OrderCellIdProps) {
  return (
    <Link
      onClick={() => goTo(nexoClient, `/orders/${order.OrderId}`)}
      appearance="primary"
    >{`#${order.OrderDisplayId}`}</Link>
  );
}

export default OrderCellId;
