import { useState } from 'react';
import { useCompany } from '../../stratus/context';
import { ICompanyAPI } from '../../stratus/interfaces';
import {
  OnboardingInitialView,
  OnboardingView1,
  OnboardingView2,
  OnboardingView3,
  OnboardingSuccessView,
} from './components';

function OnboardPage() {
  const [currentView, setCurrentView] = useState(0);
  const [company, setCompany] = useState<ICompanyAPI>();
  const { refetch } = useCompany();

  const handleGoNext = async () => {
    setCurrentView((currentView) => currentView + 1);
  };

  const handleGoPrev = () => {
    setCurrentView((currentView) => currentView - 1);
  };

  const handleFinish = () => {
    refetch();
  };

  return (
    <div className="nimbus--full-screen-center-container">
      {currentView === 0 && <OnboardingInitialView onNext={handleGoNext} />}
      {currentView === 1 && (
        <OnboardingView1 onNext={handleGoNext} setCompany={setCompany} />
      )}
      {currentView === 2 && !!company && (
        <OnboardingView2
          onNext={handleGoNext}
          onPrev={handleGoPrev}
          company={company}
        />
      )}
      {currentView === 3 && (
        <OnboardingView3
          onNext={handleGoNext}
          onPrev={handleGoPrev}
          company={company}
        />
      )}
      {currentView === 4 && <OnboardingSuccessView onFinish={handleFinish} />}
    </div>
  );
}

export default OnboardPage;
