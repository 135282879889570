import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const cstOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: '01', label: '01 - Tributável com alíquota básica' },
  { value: '02', label: '02 - Tributável com alíquota diferenciada' },
  {
    value: '03',
    label:
      '03 - Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)',
  },
  {
    value: '04',
    label: '04 - Tributável (tributação monofásica (alíquota zero))',
  },
  { value: '05', label: '05 - Tributável (Substituição Tributária)' },
  { value: '07', label: '07 - Isenta da Contribuição' },
  { value: '08', label: '08 - Operação sem Incidência da Contribuição' },
  { value: '09', label: '09 - Operação com Suspensão da Contribuição' },
  { value: '49', label: '49 - Outras Operações de Saída' },
  { value: '99', label: '99 - Outras Operações' },
];

export default cstOptionsConst;
