import { Text, DataTable, Popover, Stack } from '@tiendanube/components';
import { CloseIcon, DownloadIcon, TrashIcon } from '@tiendanube/icons';
import { UseMutationResult } from 'react-query';
import { LabelStatus } from '../../../../../stratus/components';
import { useNfe } from '../../../../../stratus/context';
import { INfe } from '../../../../../stratus/interfaces';
import { formatDateUtil } from '../../../../../stratus/utils';

const { Header, Cell, Row } = DataTable;

interface ColumnType {
  grow?: 0 | 1 | 2 | 3 | 4 | undefined;
  basis?: number | undefined;
  title: string;
  value: (
    nfe: INfe,
    deleteMutation: UseMutationResult<INfe, unknown, string, unknown>,
  ) => React.ReactNode;
}

const columns: ColumnType[] = [
  {
    grow: 0,
    basis: 70,
    title: 'Data',
    value: (nfe) => <Text>{formatDateUtil(nfe.CreatedAt, 'dd MMM')}</Text>,
  },
  {
    grow: 1,
    basis: 100,
    title: 'Cliente',
    value: (nfe) => (
      <Stack column spacing="none" align="flex-start">
        <Text bold>{nfe.RequestData.Buyer.CompanyName}</Text>
        <Text>Pedido: {nfe.OrderId}</Text>
      </Stack>
    ),
  },
  {
    grow: 1,
    basis: 100,
    title: 'Chave',
    value: (nfe) => <Text>{nfe.NfeKey?.slice(0, 20)}...</Text>,
  },
  {
    grow: 0,
    basis: 130,
    title: 'Valor',
    value: (nfe) => (
      <Text bold>
        {nfe.RequestData.ValorTotal.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Text>
    ),
  },
  {
    grow: 0,
    basis: 130,
    title: 'Status',
    value: (nfe) => <LabelStatus status={nfe.NfeStatus.Name} />,
  },
  {
    grow: 0,
    basis: 100,
    title: 'Ações',
    value: (nfe, deleteMutation) =>
      (!!nfe.Url || !!nfe.UrlXML || nfe.NfeStatus.Name === 'APPROVED') && (
        <Popover
          name=""
          position="right"
          menu={[
            nfe.Url
              ? {
                  icon: DownloadIcon,
                  children: <Text>Download PDF</Text>,
                  onClick: () => !!nfe.Url && window.open(nfe.Url, '_blank'),
                }
              : {},
            nfe.UrlXML
              ? {
                  icon: DownloadIcon,
                  children: <Text>Download XML</Text>,
                  onClick: () =>
                    !!nfe.UrlXML && window.open(nfe.UrlXML, '_blank'),
                }
              : {},
            nfe.NfeStatus.Name === 'APPROVED'
              ? {
                  icon:
                    deleteMutation.variables === nfe.InvoiceId
                      ? CloseIcon
                      : TrashIcon,
                  children: <Text appearance="danger">Cancelar Nota</Text>,
                  onClick: () => {
                    const confirmation = confirm(
                      `Tem certeza que deseja cancelar essa nota?`,
                    );

                    if (confirmation) {
                      deleteMutation.mutate(nfe.InvoiceId);
                    }
                  },
                }
              : {},
          ]}
        />
      ),
  },
];

function NfeListDesktop() {
  const { data: nfes, status, deleteMutation } = useNfe();

  return (
    <DataTable>
      <Header>
        {columns.map((column) => (
          <Cell key={column.title} grow={column.grow} basis={column.basis}>
            <Text>{column.title}</Text>
          </Cell>
        ))}
      </Header>

      {status === 'loading' &&
        ['1', '2', '3', '4'].map((id) => (
          <Row id={id} key={id} align="center">
            {columns.map((column) => (
              <Cell key={column.title} grow={column.grow} basis={column.basis}>
                <Text.Skeleton />
              </Cell>
            ))}
          </Row>
        ))}

      {nfes?.map((nfe) => (
        <Row id={nfe.InvoiceId} align="center" key={nfe.InvoiceId}>
          {columns.map((column) => (
            <Cell key={column.title} grow={column.grow} basis={column.basis}>
              {column.value(nfe, deleteMutation)}
            </Cell>
          ))}
        </Row>
      ))}
    </DataTable>
  );
}

export default NfeListDesktop;
