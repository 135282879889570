import { Button, Pagination, Stack, Text, Title } from '@tiendanube/components';
import { UndoIcon } from '@tiendanube/icons';
import { MessageCard } from '../../../../stratus/components';
import { useNfe } from '../../../../stratus/context';
import { useResponsive } from '../../../../stratus/hooks';

import NfeListDesktop from './NfeListDesktop';
import NfeListMobile from './NfeListMobile';

function NfeList(): JSX.Element {
  const { isMobile } = useResponsive();
  const { data, page, pageTotal, pageSize, totalItems, setPage, refetch } =
    useNfe();

  return (
    <>
      <Stack justify="space-between">
        <Title type="h3">Ultimas notas geradas</Title>
        <Stack spacing="tight">
          <Button icon={UndoIcon} onClick={() => refetch()}>
            Atualizar lista
          </Button>
        </Stack>
      </Stack>

      {data?.length ? (
        <>
          {isMobile ? <NfeListMobile /> : <NfeListDesktop />}
          <Stack justify="space-between" align="flex-start">
            <Stack.Item>
              {!isMobile && (
                <Text>{`Mostrando ${Math.min(
                  pageSize,
                  totalItems,
                )} NFe de ${totalItems}`}</Text>
              )}
            </Stack.Item>
            <Stack.Item>
              <Pagination
                pageTotal={pageTotal}
                pageSelected={page}
                onPageSelect={(page) => setPage(page)}
              />
            </Stack.Item>
          </Stack>
        </>
      ) : (
        <MessageCard
          title="Nenhuma NFe emitida"
          message="Você ainda não possui nenhuma NFe emitida"
        />
      )}
    </>
  );
}

export default NfeList;
