import { Button, Stack, Text, Title } from '@tiendanube/components';
import { appConfigData } from '../../../../stratus/data';
import { noovfiscalIconBlack } from '../../../../stratus/assets';

interface OnboardingInitialViewProps {
  onNext: () => void;
}

function OnboardingInitialView({ onNext }: OnboardingInitialViewProps) {
  return (
    <Stack column spacing="base">
      <img src={noovfiscalIconBlack} className="nimbus--app-icon" />
      <Title>{`Bem vindo ao ${appConfigData.appName}`}</Title>
      <Text textAlign="center">
        Para começar vamos precisar configurar algumas coisas <br />
        Mas não se preocupe, vamos guiar você pelos passos <br />
        Clique no botão abaixo para começar
      </Text>
      <Button appearance="primary" onClick={onNext}>
        Começar
      </Button>
    </Stack>
  );
}

export default OnboardingInitialView;
