import { ICompanyAPI } from '../interfaces';

const companyMock: ICompanyAPI = {
  InvoiceCompanyId: 'a5e31fa3-f4aa-4789-b3f4-2ec9136b0cf4',
  UserId: '19228',
  ExternalId: '45781225000160',
  Cnpj: '45781225000160',
  CompanyInfo: {
    RazaoSocial: 'ARTHUR CUNHA DE CARVALHO 09475985611',
    NomeFantasia: 'ARVI AROMAS E PRESENTES',
    TelefoneFiscalDDD: 31,
    TelefoneFiscal: 983348373,
    InscrEstadual: '0043021360033',
    InscrMunicipal: null,
    EmailFiscal: 'arviaromasepresentes@gmail.com',
    Address: {
      CEP: '30850420',
      Street: 'RUA ZOROASTRO DE SOUZA',
      Number: 102,
      Complement: '',
      Neighbourhood: 'DOM BOSCO',
      City: 'BELO HORIZONTE',
      State: 'MG',
      CodigoIBGE: 3106200,
    },
    HasCompanyLogo: false,
  },
  Fiscal: {
    EmiteNfe: true,
    Producao: true,
    RegimeTributario: {
      Name: 'SimplesNacional',
      Value: 1,
    },
    RegimeTributarioEspecial: {
      Name: 'MEI',
      Value: 5,
    },
    IsSimplesNacional: true,
    NfeNumeracao: [
      {
        NfeSerie: 2,
        InicioNumero: 1,
      },
    ],
  },
  CertificateId: '6390fe5082cecbc6b5b5bf7a',
  CertificateName: 'CN=ARTHUR CUNHA DE CARVALHO 09475985611:45781225000160',
  CertificateExpireAt: '2023-05-10T00:00:00',
  CreatedAt: '2022-12-07T17:57:51',
  IsDefault: false,
  Status: 'PRONTA',
  StatusDescription: 'Liberada para emissão de NFe',
  CodigoIBGE: 325245,
  SaveRemote: true,
};

export default companyMock;
