import { createContext, useContext } from 'react';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { queryKeys } from '../../data';
import { ITaxProfileAPI, ITaxProfileForm } from '../../interfaces';
import { baseAxios } from '../../services';
import { useAuthentication } from '../AuthenticationContext';
import { useCompany } from '../CompanyContext';

interface TaxContextData {
  status: 'idle' | 'error' | 'loading' | 'success';
  data: ITaxProfileAPI[] | undefined;
  refetch: () => void;
  editTaxProfile: UseMutateAsyncFunction<
    ITaxProfileAPI,
    unknown,
    {
      id: string;
      taxProfile: ITaxProfileForm;
    },
    unknown
  >;
  createTaxProfile: UseMutateAsyncFunction<
    ITaxProfileAPI,
    unknown,
    ITaxProfileForm,
    unknown
  >;
  deleteTaxProfile: UseMutateAsyncFunction<
    ITaxProfileAPI,
    unknown,
    string,
    unknown
  >;
}

const TaxContext = createContext<TaxContextData>({} as TaxContextData);

interface TaxProviderProps {
  children: React.ReactNode;
}

export function TaxProvider({ children }: TaxProviderProps) {
  const queryClient = useQueryClient();

  const { storeId } = useAuthentication();
  const { hasCompany, data: company } = useCompany();

  const { data, status, refetch } = useQuery(
    [queryKeys.taxProfiles, storeId],
    baseAxios.getTaxProfiles,
    { enabled: hasCompany },
  );

  const { mutateAsync: editTaxProfile } = useMutation(
    ({ id, taxProfile }: { id: string; taxProfile: ITaxProfileForm }) =>
      baseAxios.updateTaxProfile(
        id,
        taxProfile,
        company?.InvoiceCompanyId ?? '',
      ),
    {
      onSuccess: () => queryClient.invalidateQueries('taxProfiles'),
    },
  );

  const { mutateAsync: createTaxProfile } = useMutation(
    (taxProfile: ITaxProfileForm) =>
      baseAxios.createTaxProfile(taxProfile, company?.InvoiceCompanyId ?? ''),
    {
      onSuccess: () => queryClient.invalidateQueries('taxProfiles'),
    },
  );

  const { mutateAsync: deleteTaxProfile } = useMutation(
    (taxProfileId: string) => baseAxios.deleteTaxProfile(taxProfileId),
    {
      onSuccess: () => queryClient.invalidateQueries('taxProfiles'),
    },
  );

  return (
    <TaxContext.Provider
      value={{
        data,
        status,
        refetch,
        editTaxProfile,
        createTaxProfile,
        deleteTaxProfile,
      }}
    >
      {children}
    </TaxContext.Provider>
  );
}

export function useTax(): TaxContextData {
  const context = useContext(TaxContext);

  if (!context) {
    throw new Error('useTax must be used within an TaxProvider');
  }

  return context;
}
