import { useEffect, useMemo, useState } from 'react';
import { Card, Input, InterfaceNameValue, Stack } from '@tiendanube/components';
import { CancelAndSaveButtons } from '..';
import { ICompanyForm, ICompanyAPI } from '../../interfaces';
import { useResponsive } from '../../hooks';
import { companySchema } from '../../schemas';
import { companyDAO } from '../../services';
import { loggerUtil } from '../../utils';
import { useToast } from '../../context';
import CompanyLogo from './CompanyLogo';

interface CompanyCardProps {
  defaultErrors?: Record<string, string>;
  isCollapsible?: boolean;
  isOpenByDefault?: boolean;
  company?: ICompanyAPI;
  disableLogoUpload?: boolean;
  updateCompany?: (company: ICompanyForm) => Promise<ICompanyAPI>;
  onChange?: (data: InterfaceNameValue) => void;
}

function CompanyCard({
  isCollapsible = true,
  disableLogoUpload = true,
  isOpenByDefault,
  defaultErrors,
  company,
  updateCompany,
  onChange,
}: CompanyCardProps) {
  const { isMobile } = useResponsive();
  const { showError, showApiError, showSuccess } = useToast();

  const initialValues = useMemo<ICompanyForm>(
    () => companyDAO.companyToForm(company),
    [company],
  );

  const [errors, setErrors] = useState<Record<string, string>>(
    defaultErrors ?? {},
  );
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);

  useEffect(() => {
    if (defaultErrors) {
      setErrors(defaultErrors);
    }
  }, [defaultErrors]);

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
    onChange?.({ name, value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await companySchema.safeParseAsync(data);
      if (parsedData.success) {
        await updateCompany?.(parsedData.data);
        showSuccess('Dados atualizados com sucesso');
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        showError('Erro ao salvar os dados, confira os campos novamente');
      }
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Falha ao atualizar os dados da empresa, tente novamente mais tarde',
      );
    }

    setLoading(false);
  };

  const handleReset = () => {
    setData(initialValues);
  };

  return (
    <Card
      title="Dados Cadastrais"
      isCollapsible={isCollapsible}
      isOpenByDefault={isOpenByDefault}
    >
      <Stack column align="stretch">
        <Stack column={isMobile} align="stretch">
          <CompanyLogo disableLogoUpload={disableLogoUpload} />

          <Stack.Item fill>
            <Stack column align="stretch">
              <Input
                name="RazaoSocial"
                label="Razão Social"
                placeholder="Razão Social"
                value={data.RazaoSocial}
                appearance={errors['RazaoSocial'] ? 'danger' : 'default'}
                helpText={errors['RazaoSocial']}
                disabled
              />

              <Input
                name="NomeFantasia"
                label="Nome Fantasia"
                placeholder="Nome Fantasia"
                value={data.NomeFantasia}
                appearance={errors['NomeFantasia'] ? 'danger' : 'default'}
                helpText={errors['NomeFantasia']}
                disabled
              />
            </Stack>
          </Stack.Item>
        </Stack>

        <Stack align="flex-start" justify="flex-start" spacing="base">
          <Stack.Item fill>
            <Input
              name="Cnpj"
              label="CNPJ"
              placeholder="CNPJ"
              value={data.Cnpj}
              appearance={errors['Cnpj'] ? 'danger' : 'default'}
              helpText={errors['Cnpj']}
              disabled
            />
          </Stack.Item>

          <Stack.Item fill>
            <Input
              name="InscrEstadual"
              label="Inscrição Estadual"
              placeholder="Inscrição Estadual"
              value={data.InscrEstadual}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['InscrEstadual'] ? 'danger' : 'default'}
              helpText={errors['InscrEstadual']}
            />
          </Stack.Item>
        </Stack>

        <Stack
          column={isMobile}
          align="stretch"
          justify="flex-start"
          spacing="base"
        >
          <Stack.Item fill>
            <Input
              name="EmailFiscal"
              label="E-mail Fiscal"
              placeholder="E-mail Fiscal"
              value={data.EmailFiscal}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['EmailFiscal'] ? 'danger' : 'default'}
              helpText={errors['EmailFiscal']}
            />
          </Stack.Item>

          <Stack.Item fill>
            <Stack>
              <Stack.Item>
                <Input
                  name="TelefoneFiscalDDD"
                  label="DDD"
                  placeholder="DDD"
                  inputMode="decimal"
                  maxLength={2}
                  value={data.TelefoneFiscalDDD}
                  onChange={handleChange}
                  disabled={isLoading}
                  appearance={
                    errors['TelefoneFiscalDDD'] ? 'danger' : 'default'
                  }
                  helpText={errors['TelefoneFiscalDDD']}
                />
              </Stack.Item>
              <Stack.Item fill>
                <Input
                  name="TelefoneFiscal"
                  label="Telefone Financeiro"
                  placeholder="Telefone"
                  inputMode="decimal"
                  maxLength={9}
                  value={data.TelefoneFiscal}
                  onChange={handleChange}
                  disabled={isLoading}
                  appearance={errors['TelefoneFiscal'] ? 'danger' : 'default'}
                  helpText={errors['TelefoneFiscal']}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>

        {!!updateCompany && (
          <CancelAndSaveButtons
            saveText="Salvar"
            onCancel={handleReset}
            onSave={handleSubmit}
            cancelText="Cancelar"
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        )}
      </Stack>
    </Card>
  );
}

export default CompanyCard;
