import {
  Button,
  Input,
  InterfaceNameValue,
  Select,
  Stack,
} from '@tiendanube/components';
import React, { useState } from 'react';
import { ITaxProfileForm } from '../../interfaces';
import Divider from '../Divider';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import {
  cfopOptionsConst,
  cofinsOptionsConst,
  finalidadeOptionsConst,
  freightCodeOptionsConst,
  icmsOptionsConst,
  orderIdentifierOptionsConst,
  originOptionsConst,
  pisOptionsConst,
  printTypeOptionsConst,
  productIdentifierOptionsConst,
  yesNoOptionsConst,
} from '../../constants';

interface TaxProfileFormProps {
  taxProfile: ITaxProfileForm;
  isLoading: boolean;
  errors?: Record<string, string>;
  showAdvancedConfig?: boolean;
  onChange?: (data: InterfaceNameValue) => void;
}

function TaxProfileForm({
  taxProfile,
  isLoading,
  errors,
  showAdvancedConfig = true,
  onChange,
}: TaxProfileFormProps) {
  const [isAdvancedVisible, setAdvancedVisible] = useState(false);
  const [data, setData] = useState(taxProfile);

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
    onChange?.({ name, value });
  };

  return (
    <Stack column align="stretch">
      <Input
        name="Name"
        label="Nome"
        placeholder="Nome"
        value={data.Name}
        onChange={handleChange}
        disabled={isLoading}
        helpText={errors?.['Name']}
        appearance={errors?.['Name'] ? 'danger' : 'default'}
      />

      <Input
        multiRows
        name="Description"
        label="Descrição"
        placeholder="Descrição"
        value={data.Description}
        onChange={handleChange}
        disabled={isLoading}
        helpText={errors?.['Description']}
        appearance={errors?.['Description'] ? 'danger' : 'default'}
      />

      <Divider />

      <Input
        name="Natureza"
        label="Natureza da operação"
        placeholder="Natureza da operação"
        value={data.Natureza}
        onChange={handleChange}
        disabled={isLoading}
        helpText={errors?.['Natureza']}
        appearance={errors?.['Natureza'] ? 'danger' : 'default'}
      />

      <Select
        name="CodigoCFOP"
        label="Código CFOP"
        placeholder="Selecione uma opção"
        options={cfopOptionsConst}
        onChange={handleChange}
        value={data.CodigoCFOP}
        helpText={errors?.['CodigoCFOP']}
        // appearance={errors?.['CodigoCFOP'] ? 'danger' : 'default'}
      />

      <Select
        name="OrigemICMS"
        label="ICMS - Origem padrão dos produtos"
        placeholder="Selecione uma opção"
        options={originOptionsConst}
        onChange={handleChange}
        value={data.OrigemICMS}
        helpText={errors?.['OrigemICMS']}
        // appearance={errors?.['OrigemICMS'] ? 'danger' : 'default'}
      />

      <Select
        name="CodigoICMS"
        label="ICMS - Código de operação padrão (CST/CSOSN)"
        placeholder="Selecione uma opção"
        options={icmsOptionsConst}
        onChange={handleChange}
        value={data.CodigoICMS}
        helpText={errors?.['CodigoICMS']}
        // appearance={errors?.['CodigoICMS'] ? 'danger' : 'default'}
      />

      <Select
        name="CodigoPIS"
        label="PIS - Código de operação padrão (CST)"
        placeholder="Selecione uma opção"
        options={pisOptionsConst}
        onChange={handleChange}
        value={data.CodigoPIS}
        helpText={errors?.['CodigoPIS']}
        // appearance={errors?.['CodigoPIS'] ? 'danger' : 'default'}
      />

      <Select
        name="CodigoCOFINS"
        label="COFINS - Código de operação padrão (CST)"
        placeholder="Selecione uma opção"
        options={cofinsOptionsConst}
        onChange={handleChange}
        value={data.CodigoCOFINS}
        helpText={errors?.['CodigoCOFINS']}
        // appearance={errors?.['CodigoCOFINS'] ? 'danger' : 'default'}
      />

      <Select
        name="DefaultFreightCode"
        label="Código frete padrão"
        placeholder="Selecione uma opção"
        options={freightCodeOptionsConst}
        onChange={handleChange}
        value={data.DefaultFreightCode}
        helpText={errors?.['DefaultFreightCode']}
        // appearance={errors?.['DefaultFreightCode'] ? 'danger' : 'default'}
      />

      {showAdvancedConfig && (
        <Button
          icon={isAdvancedVisible ? ChevronUpIcon : ChevronDownIcon}
          onClick={() => setAdvancedVisible(!isAdvancedVisible)}
        >
          {isAdvancedVisible
            ? 'Esconder configurações avançadas'
            : 'Mostrar configurações avançadas'}
        </Button>
      )}

      {isAdvancedVisible && (
        <>
          <Select
            name="OrderIdentifier"
            label="Utilizar no número do pedido"
            placeholder="Selecione uma opção"
            options={orderIdentifierOptionsConst}
            onChange={handleChange}
            value={data.OrderIdentifier}
            helpText={errors?.['OrderIdentifier']}
            // appearance={errors?.['OrderIdentifier'] ? 'danger' : 'default'}
          />

          <Select
            name="ProductIdentifier"
            label="Utilizar no código do produto"
            placeholder="Selecione uma opção"
            options={productIdentifierOptionsConst}
            onChange={handleChange}
            value={data.ProductIdentifier}
            helpText={errors?.['ProductIdentifier']}
            // appearance={errors?.['ProductIdentifier'] ? 'danger' : 'default'}
          />

          <Select
            name="RejectWithoutEAN"
            label="Rejeitar emissão se o produto estiver sem GTIN/EAN"
            placeholder="Selecione uma opção"
            options={yesNoOptionsConst}
            onChange={handleChange}
            value={data.RejectWithoutEAN}
            helpText={errors?.['RejectWithoutEAN']}
            // appearance={errors?.['RejectWithoutEAN'] ? 'danger' : 'default'}
          />

          <Select
            name="Presencial"
            label="Operação presencial?"
            placeholder="Selecione uma opção"
            options={yesNoOptionsConst}
            onChange={handleChange}
            value={data.Presencial}
            helpText={errors?.['Presencial']}
            // appearance={errors?.['Presencial'] ? 'danger' : 'default'}
          />

          <Select
            name="ConsumidorFinal"
            label="Consumidor final?"
            placeholder="Selecione uma opção"
            options={yesNoOptionsConst}
            onChange={handleChange}
            value={data.ConsumidorFinal}
            helpText={errors?.['ConsumidorFinal']}
            // appearance={errors?.['ConsumidorFinal'] ? 'danger' : 'default'}
          />

          <Select
            name="TipoImpressao"
            label="Tipo de impressão"
            placeholder="Selecione uma opção"
            options={printTypeOptionsConst}
            onChange={handleChange}
            value={data.TipoImpressao}
            helpText={errors?.['TipoImpressao']}
            // appearance={errors?.['TipoImpressao'] ? 'danger' : 'default'}
          />

          <Select
            name="Finalidade"
            label="Finalidade da Operação"
            placeholder="Selecione uma opção"
            options={finalidadeOptionsConst}
            onChange={handleChange}
            value={data.Finalidade}
            helpText={errors?.['Finalidade']}
            // appearance={errors?.['Finalidade'] ? 'danger' : 'default'}
          />

          <Input
            multiRows
            name="NotasComplementaresDANFE"
            label="Informações complementares - DANFE"
            placeholder="Informações complementares - DANFE"
            value={data.NotasComplementaresDANFE}
            onChange={handleChange}
            disabled={isLoading}
            helpText={errors?.['NotasComplementaresDANFE']}
            appearance={
              errors?.['NotasComplementaresDANFE'] ? 'danger' : 'default'
            }
          />

          <Input
            name="PermissionIds"
            label="CPF/CNPJs autorizados a acessar o XML da NFe"
            placeholder="CPF/CNPJs autorizados a acessar o XML da NFe"
            value={data.PermissionIds}
            onChange={handleChange}
            disabled={isLoading}
            helpText={
              errors?.['PermissionIds'] ??
              'Separados por vírgula. No máximo 10 entradas.'
            }
            appearance={errors?.['PermissionIds'] ? 'danger' : 'default'}
          />

          <Input
            name="CancellationDefaultReason"
            label="Justificativa em caso de cancelamento da NFe"
            placeholder="Justificativa em caso de cancelamento da NFe"
            value={data.CancellationDefaultReason}
            onChange={handleChange}
            disabled={isLoading}
            helpText={errors?.['CancellationDefaultReason']}
            appearance={
              errors?.['CancellationDefaultReason'] ? 'danger' : 'default'
            }
          />
        </>
      )}
    </Stack>
  );
}

export default TaxProfileForm;
