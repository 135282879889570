import { Card, Text } from '@tiendanube/components';
import React from 'react';

interface MessageCardProps {
  title: string;
  message?: string;
}

function MessageCard({ title, message }: MessageCardProps) {
  return <Card title={title}>{!!message && <Text>{message}</Text>}</Card>;
}

export default MessageCard;
