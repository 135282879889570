import { InterfaceNameValue, Modal } from '@tiendanube/components';
import React, { useMemo, useState } from 'react';
import { useTax, useToast } from '../../../../context';
import { ITaxProfileAPI, ITaxProfileForm } from '../../../../interfaces';
import { taxProfileSchema } from '../../../../schemas';
import { taxProfileDAO } from '../../../../services';
import { loggerUtil } from '../../../../utils';
import TaxProfileForm from '../../../TaxProfileForm';

interface TaxProfileModalProps {
  isVisible: boolean;
  editingProfile?: ITaxProfileAPI;
  onClose: () => void;
}

function TaxProfileModal({
  isVisible,
  editingProfile,
  onClose,
}: TaxProfileModalProps) {
  const { showError, showApiError } = useToast();
  const { editTaxProfile, createTaxProfile } = useTax();

  const initialData = useMemo<ITaxProfileForm>(
    () => taxProfileDAO.taxToForm(editingProfile),
    [editingProfile],
  );

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await taxProfileSchema.safeParseAsync(data);
      if (parsedData.success) {
        if (editingProfile?.InvoiceConfigurationId) {
          await editTaxProfile({
            id: editingProfile.InvoiceConfigurationId,
            taxProfile: parsedData.data as ITaxProfileForm,
          });
        } else {
          await createTaxProfile(parsedData.data as ITaxProfileForm);
        }
        onClose();
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        showError('Erro ao salvar os dados, confira os campos novamente');
      }
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Erro ao salvar os dados do perfil fiscal, tente novamente mais tarde',
      );
    }

    setLoading(false);
  };

  return (
    <Modal
      show={isVisible}
      onDismiss={onClose}
      primaryAction={{
        appearance: 'primary',
        children: editingProfile ? 'Salvar' : 'Criar',
        onClick: handleSave,
      }}
      secondaryAction={{ onClick: onClose, children: 'Cancelar' }}
      title={
        editingProfile
          ? `Editando perfil ${editingProfile.Name}`
          : 'Novo perfil'
      }
    >
      <TaxProfileForm
        isLoading={isLoading}
        taxProfile={initialData}
        onChange={handleChange}
        errors={errors}
      />
    </Modal>
  );
}

export default TaxProfileModal;
