import { IOrderAPI, INfeCreateOrderInfoForm } from '../interfaces';

class OrderDAO {
  public orderToOrderData(orderAPI: IOrderAPI): INfeCreateOrderInfoForm {
    return {
      Buyer: {
        FirstName: orderAPI.BuyerFirstName,
        LastName: orderAPI.BuyerLastName,
        Email: orderAPI.BuyerEmail,
        Document: orderAPI.BillingContact.Document,
        CompanyRegistryName: orderAPI.BuyerFirstName,
      },
      Address: {
        Street: orderAPI.BillingContact.Address.Street,
        Number: orderAPI.BillingContact.Address.Number,
        Additional: orderAPI.BillingContact.Address.Additional,
        Neighbour: orderAPI.BillingContact.Address.Neighbour,
        City: orderAPI.BillingContact.Address.City,
        State: orderAPI.BillingContact.Address.State,
        ZipCode: orderAPI.BillingContact.Address.ZipCode,
        IBGECode: '',
      },
      Payments: orderAPI.Payments.map((payment) => ({
        AVista: payment?.Installments === 1,
        Method: payment?.Method,
        PaidAmount: payment?.AmountPaid,
        CNPJIntermediadorPagamento: payment.Charges?.[0]?.Details,
      })),
      Items: orderAPI.Items.map((item) => ({
        Sku: item.Sku,
        Name: item.Name,
        Quantity: item.Quantity,
        PriceEach: item.PriceEach,
        ItemDiscount: item.ItemDiscount,
        PriceTotal: item.PriceTotal,
        CSOSN: item.FiscalData?.CSOSN,
        CST: item.FiscalData?.CST,
        CFOP: item.FiscalData?.CFOP,
        ICMSOrigem: item.FiscalData?.ICMSOrigem,
        NCM: item.FiscalData?.NCM,
        EAN: item.FiscalData?.EAN,
        UNIDADE: item.FiscalData?.UNIDADE,
      })),
      CNPJIntermediador: orderAPI.StoreInfo.CNPJIntermediadorPagamento,
      AmountShipping: orderAPI.AmountShipping,
      AmountDiscount: orderAPI.AmountDiscount,
      AmountTotal: orderAPI.AmountTotal,
      ShippingMethod: orderAPI.ShippingInfo.TrackCarrier,
    };
  }
}
const orderDAO = new OrderDAO();

export default orderDAO;
