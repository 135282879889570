import {
  Link,
  Text,
  Stack,
  DataTable,
  Title,
  IconButton,
} from '@tiendanube/components';
import { PencilIcon, PlusCircleIcon } from '@tiendanube/icons';
import { useState } from 'react';
import { useTax } from '../../../../context';
import { ITaxProfileAPI } from '../../../../interfaces';
import TaxProfileModal from './TaxProfileModal';

const { Header, Cell, Row } = DataTable;

function TaxProfileTable() {
  const { data: taxProfiles } = useTax();

  const [isVisible, setVisible] = useState(false);
  const [editingProfile, setEditingProfile] = useState<ITaxProfileAPI>();

  // const handleDeleteProfile = (profile: ITaxProfileAPI) => {
  //   const confirmation = confirm(
  //     `Tem certeza que deseja deletar o perfil ${profile.Name}`,
  //   );

  //   if (confirmation) {
  //     deleteTaxProfile(profile.InvoiceConfigurationId);
  //   }
  // };

  return (
    <Stack column>
      <Stack.Item fill>
        <Stack align="center" justify="space-between">
          <Title type="h3">Perfis para emissão da NFe</Title>
          {!taxProfiles?.length && (
            <IconButton
              ariaLabel="Adicionar perfil"
              icon={PlusCircleIcon}
              onClick={() => setVisible(true)}
            />
          )}
        </Stack>
      </Stack.Item>
      <DataTable>
        <Header>
          <Cell grow={1}>
            <Text>Nome</Text>
          </Cell>
          <Cell grow={1}>
            <Text>Descrição</Text>
          </Cell>
          <Cell grow={0} basis={60}>
            <Text>Ações</Text>
          </Cell>
        </Header>

        {taxProfiles?.map((profile, index) => (
          <Row
            id={profile.InvoiceConfigurationId ?? `${index}`}
            key={profile.InvoiceConfigurationId}
            align="center"
          >
            <Cell grow={1}>
              <Text bold>{profile.Name.slice(0, 60)}</Text>
            </Cell>
            <Cell grow={1}>
              <Text>{profile.Description.slice(0, 100)}</Text>
            </Cell>
            <Cell grow={0} basis={60}>
              <Stack align="center" justify="space-between" spacing="none">
                <Link
                  onClick={() => {
                    setEditingProfile(profile);
                    setVisible(true);
                  }}
                  icon={PencilIcon}
                />
                {/* <Link
                  icon={TrashIcon}
                  appearance="danger"
                  onClick={() => handleDeleteProfile(profile)}
                /> */}
              </Stack>
            </Cell>
          </Row>
        ))}
      </DataTable>

      <TaxProfileModal
        key={editingProfile?.InvoiceConfigurationId ?? 'new'}
        isVisible={isVisible}
        editingProfile={editingProfile}
        onClose={() => {
          setVisible(false);
          setEditingProfile(undefined);
        }}
      />
    </Stack>
  );
}

export default TaxProfileTable;
