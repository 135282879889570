import { Button, Stack, Text, Title } from '@tiendanube/components';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuthentication, useCompany, useUser } from '../../stratus/context';

function ErrorPage() {
  const { isAuthenticated, authenticate } = useAuthentication();
  const { status: userStatus, refetch: refetchUser } = useUser();
  const { status: companyStatus, refetch: refetchCompany } = useCompany();

  useEffect(() => {
    const message = `[ErrorPage] isAuthenticated:${isAuthenticated} userStatus:${userStatus}, companyStatus:${companyStatus}`;
    Sentry.captureMessage(message, 'error');
  }, [isAuthenticated, userStatus, companyStatus]);

  const handleTryAgain = () => {
    if (!isAuthenticated) {
      authenticate();
    } else if (userStatus === 'error') {
      refetchUser();
    } else if (companyStatus === 'error') {
      refetchCompany();
    }
  };

  return (
    <div className="nimbus--full-screen-center-container">
      <Stack column spacing="tight">
        <Title>Erro :(</Title>
        <Text textAlign="center">
          Ocorreu um erro ao baixar seus dados.
          <br />
          Você pode tentar novamente clicando no botão abaixo
        </Text>
        <Button onClick={() => handleTryAgain()}>Tentar novamente</Button>
      </Stack>
    </div>
  );
}

export default ErrorPage;
