import { createContext, useCallback, useContext, useState } from 'react';
import { Toast } from '@tiendanube/components';

type ToastAppearance =
  | 'success'
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'danger';

interface ToastData {
  message: string;
  appearance: ToastAppearance;
}

interface ToastContextData {
  showToast: (data: ToastData) => void;
  showError: (message: string) => void;
  showApiError: (error: any, fallbackMessage: string) => void;
  showSuccess: (message: string) => void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

interface ToastProviderProps {
  children: React.ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [toastData, showToast] = useState<ToastData>();

  const showError = useCallback((message: string) => {
    showToast({ message, appearance: 'danger' });
  }, []);

  const showApiError = useCallback((error: any, fallbackMessage: string) => {
    let message = fallbackMessage;
    if (typeof error?.response?.data?.Error?.Message === 'string') {
      message = error.response.data.Error.Message;
    }
    showToast({ message, appearance: 'danger' });
  }, []);

  const showSuccess = useCallback((message: string) => {
    showToast({ message, appearance: 'success' });
  }, []);

  return (
    <ToastContext.Provider
      value={{ showToast, showError, showSuccess, showApiError }}
    >
      {children}
      {!!toastData && (
        <Toast
          label={toastData.message}
          appearance={toastData.appearance}
          onClose={() => showToast(undefined)}
        />
      )}
    </ToastContext.Provider>
  );
}

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
}
