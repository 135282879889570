import {
  Button,
  Chip,
  Input,
  Link,
  Stack,
  Text,
  Thumbnail,
  Title,
} from '@tiendanube/components';
import { useState } from 'react';
import { useToast } from '../../../../stratus/context';
import { appConfigData } from '../../../../stratus/data';
import { ICompanyAPI } from '../../../../stratus/interfaces';
import { baseAxios } from '../../../../stratus/services';
import { loggerUtil } from '../../../../stratus/utils';

interface OnboardingView1Props {
  onNext: () => void;
  setCompany: (company: ICompanyAPI) => void;
}

function OnboardingView1({ onNext, setCompany }: OnboardingView1Props) {
  const { showError, showApiError } = useToast();

  const [certificate, setCertificate] = useState<File>();
  const [certificatePassword, setCertificatePassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSelectFile = (files: File[]) => {
    const [file] = files;

    const allowedTypes = appConfigData.certificateFileType.split(',');
    if (!allowedTypes.includes(file.type)) {
      showError('Tipo de arquivo inválido, favor usar .p12 ou .pfx');
      return;
    }

    if (file.size >= appConfigData.certificateFileSizeLimit) {
      showError(
        'Tamanho do arquivo inválido, favor usar arquivo menor que 15 mb',
      );
      return;
    }

    setCertificate(file);
  };

  const handleReset = () => {
    if (loading) {
      return;
    }

    setCertificatePassword('');
    setCertificate(undefined);
  };

  const handleSubmit = async () => {
    if (!certificate) {
      return;
    }

    setLoading(true);

    try {
      const data = await baseAxios.uploadCertificate(
        certificate,
        certificatePassword,
      );
      setCompany(data);
      onNext();
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Ocorreu um erro ao fazer o upload do certificado, tente novamente mais tarde',
      );
      setLoading(false);
    }
  };

  return (
    <Stack column spacing="base">
      <Stack.Item fill>
        <Stack column spacing="none">
          <Title type="h6">1 / 3</Title>
          <Title type="h1">Certificado Digital</Title>
        </Stack>
      </Stack.Item>

      <Stack.Item fill>
        <Stack column spacing="none">
          <Text textAlign="center">
            Primeiro faça o upload do seu Certificado Digital A1{' '}
            <small>
              (<strong>.p12</strong> ou <strong>.pfx</strong>)
            </small>
          </Text>
          <Text>
            Vamos precisar dele para configurar sua conta e emitir suas notas
            fiscais
          </Text>
        </Stack>
      </Stack.Item>

      {certificate ? (
        <Stack.Item fill>
          <Stack column spacing="base">
            <Chip
              id="certificate"
              label={certificate.name}
              onDismiss={handleReset}
            />
            <Input
              name="password"
              type="password"
              label="Senha do certificado"
              placeholder="Senha"
              value={certificatePassword}
              onChange={({ value }) => setCertificatePassword(value)}
              disabled={loading}
              onSubmit={handleSubmit}
            />
          </Stack>
        </Stack.Item>
      ) : (
        <Thumbnail.File
          aspectRatio="2-1"
          ariaLabel="Certificado Digital"
          accept={appConfigData.certificateFileType}
          width="200px"
          multiple={false}
          onChange={handleSelectFile}
        />
      )}

      <Button
        disabled={certificatePassword.length < 3 || loading}
        appearance="primary"
        onClick={handleSubmit}
        spinner={loading}
      >
        Continuar
      </Button>

      {!certificate && (
        <Stack column spacing="none">
          <Text textAlign="center" appearance="danger" size="highlight">
            Não tem um certificado digital A1?
          </Text>
          <Text appearance="primary">
            <Link
              target="_blank"
              href="https://noovfiscal.com.br/certificado"
              appearance="primary"
            >
              Clique aqui e compre agora
            </Link>
          </Text>
        </Stack>
      )}
    </Stack>
  );
}

export default OnboardingView1;
