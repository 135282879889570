import { Card, Stack, Text, Tooltip } from '@tiendanube/components';
import React from 'react';
import { useStatistics } from '../../../../stratus/context';
import { useResponsive } from '../../../../stratus/hooks';

function Statistics() {
  const { isMobile } = useResponsive();
  const { data } = useStatistics();

  if (!data) {
    return null;
  }

  return (
    <Stack column spacing="base">
      <Card title="Informações da conta" isCollapsible={false}>
        <Stack align="stretch">
          {/* TODO */}
          {/* <Stack.Item fill>
            <Stack column spacing="none">
              <Text
                bold
                appearance="primary"
                size={isMobile ? 'highlight' : 'featured'}
              >
                R$ 2.000,00
              </Text>
              <Text>Total faturado</Text>
            </Stack>
          </Stack.Item> */}
          <Stack.Item fill>
            <Stack column spacing="none">
              <Text
                bold
                appearance="primary"
                size={isMobile ? 'highlight' : 'featured'}
              >
                {`${data.CreatedInvoices} `}
                <Text bold size="base">
                  /{' '}
                  <Tooltip labelText={`${data.AvailableInvoices}`}>
                    <Text appearance="light">
                      Esse é o limite mensal do seu plano
                    </Text>
                  </Tooltip>
                </Text>
              </Text>
              <Text>Notas emitidas</Text>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
    </Stack>
  );
}

export default Statistics;
