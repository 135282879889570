import { createContext, useContext, useState } from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import { appConfigData, queryKeys } from '../../data';
import { INfe, INfeCreateForm, INfeCreateResponse } from '../../interfaces';
import { baseAxios } from '../../services';
import { useAuthentication } from '../AuthenticationContext';
import { useCompany } from '../CompanyContext';

interface NfeContextData {
  status: 'idle' | 'error' | 'loading' | 'success';
  data: INfe[] | undefined;
  page: number;
  pageTotal: number;
  pageSize: number;
  totalItems: number;
  setPage: (page: number) => void;
  setPageSize: (newPageSize: number) => void;
  refetch: () => void;
  deleteMutation: UseMutationResult<INfe, unknown, string, unknown>;
  issueNFeMutation: UseMutationResult<
    INfeCreateResponse,
    unknown,
    INfeCreateForm,
    unknown
  >;
}

const NfeContext = createContext<NfeContextData>({} as NfeContextData);

interface NfeProviderProps {
  children: React.ReactNode;
}

export function NfeProvider({ children }: NfeProviderProps) {
  const queryClient = useQueryClient();

  const { storeId } = useAuthentication();
  const { hasCompany } = useCompany();

  const [pageSize, setPageSize] = useState<number>(
    appConfigData.defaultPageSize,
  );
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageTotal = Math.ceil(totalItems / pageSize);

  const { data, status, refetch } = useQuery(
    [queryKeys.nfes, page, pageSize, storeId],
    () => baseAxios.getNfes(pageSize, page),
    {
      enabled: hasCompany,
      onSuccess: (data) => {
        setTotalItems(data?.Total ?? data?.Response?.length ?? 0);
      },
    },
  );

  const deleteMutation = useMutation((id: string) => baseAxios.deleteNfe(id), {
    onSuccess: () =>
      queryClient.invalidateQueries([queryKeys.nfes, queryKeys.orders]),
  });

  const issueNFeMutation = useMutation(
    (ordersToIssue: INfeCreateForm) => baseAxios.issueNFesOrders(ordersToIssue),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.nfes, queryKeys.orders]),
    },
  );

  return (
    <NfeContext.Provider
      value={{
        data: data?.Response,
        page,
        status,
        pageTotal,
        pageSize,
        totalItems,
        setPage,
        refetch,
        setPageSize,
        issueNFeMutation,
        deleteMutation,
      }}
    >
      {children}
    </NfeContext.Provider>
  );
}

export function useNfe(): NfeContextData {
  const context = useContext(NfeContext);

  if (!context) {
    throw new Error('useNfe must be used within an NfeProvider');
  }

  return context;
}
