import { InternalAxiosRequestConfig } from 'axios';

import { appConfigData } from '../data';
import jwtDecode from 'jwt-decode';
import { getSessionToken } from '@tiendanube/nexo/helpers';
import nexoClient from './nexoClient';
import { loggerUtil } from '../utils';
import { NSTokenPayload } from '../interfaces';

class BaseAuthAxios {
  public async getInterceptorConfig(config: InternalAxiosRequestConfig<any>) {
    let token = await this.getToken();

    const decodedToken = jwtDecode<NSTokenPayload>(token);
    const expireTime = decodedToken.exp ?? 0;

    const expiredDate = new Date(expireTime * 1000);
    const now = new Date();

    if (now >= expiredDate) {
      token = await this.getToken(true);
    }

    loggerUtil('token', token);

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.NSApp = appConfigData.header.nsApp;

    return config;
  }

  private async getToken(force = false) {
    if (!force) {
      const token = localStorage.getItem(
        appConfigData.storageNuvemshopTokenKey,
      );

      if (token) {
        return token;
      }
    }

    const token = await getSessionToken(nexoClient);
    localStorage.setItem(appConfigData.storageNuvemshopTokenKey, token);
    return token;
  }
}

const baseAuthAxios = new BaseAuthAxios();

export default baseAuthAxios;
