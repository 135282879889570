import { createContext, useContext, useEffect, useState } from 'react';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { queryKeys } from '../../data';
import {
  IAddressForm,
  ICompanyForm,
  ICompanyAPI,
  ITaxForm,
  ICertificateAPI,
  IAPIResponse,
} from '../../interfaces';
import { baseAxios } from '../../services';
import { useAuthentication } from '../AuthenticationContext';
import { useUser } from '../UserContext';

interface CompanyContextData {
  status: 'idle' | 'error' | 'loading' | 'success';
  hasCompany: boolean;
  companyLogo: any;
  data: ICompanyAPI | undefined;
  refetch: () => void;
  update: UseMutateAsyncFunction<ICompanyAPI, unknown, ICompanyAPI, unknown>;
  updateCompany: UseMutateAsyncFunction<
    ICompanyAPI,
    unknown,
    ICompanyForm,
    unknown
  >;
  updateAddress: UseMutateAsyncFunction<
    ICompanyAPI,
    unknown,
    IAddressForm,
    unknown
  >;
  updateTax: UseMutateAsyncFunction<ICompanyAPI, unknown, ITaxForm, unknown>;
  uploadCertificate: UseMutateAsyncFunction<
    ICompanyAPI,
    unknown,
    ICertificateAPI,
    unknown
  >;
  uploadCompanyLogo: (file: any) => Promise<void>;
  deleteCompanyLogo: () => Promise<void>;
  deleteCertificate: UseMutateAsyncFunction<
    IAPIResponse<boolean>,
    unknown,
    void,
    unknown
  >;
}

const CompanyContext = createContext<CompanyContextData>(
  {} as CompanyContextData,
);

interface CompanyProviderProps {
  children: React.ReactNode;
}

export function CompanyProvider({ children }: CompanyProviderProps) {
  const queryClient = useQueryClient();
  const { isAuthenticated, storeId } = useAuthentication();
  const { data: user } = useUser();

  const [companyLogo, setCompanyLogo] = useState<any>(null);

  const { data, status, refetch } = useQuery(
    [queryKeys.company, storeId],
    baseAxios.getCompany,
    { enabled: isAuthenticated && !!user },
  );

  useEffect(() => {
    if (data?.InvoiceCompanyId) {
      baseAxios
        .getCompanyLogo(data.InvoiceCompanyId)
        .then((response) => setCompanyLogo(response));
    }
  }, [data?.InvoiceCompanyId]);

  const uploadCompanyLogo = async (file: File) => {
    if (data?.InvoiceCompanyId) {
      const result = await baseAxios.uploadCompanyLogo(
        data.InvoiceCompanyId,
        file,
      );

      if (result) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const imageUrl = event.target?.result;
          setCompanyLogo(imageUrl);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const deleteCompanyLogo = async () => {
    if (data?.InvoiceCompanyId) {
      const result = await baseAxios.deleteCompanyLogo(data.InvoiceCompanyId);
      if (result) {
        setCompanyLogo(null);
      }
    }
  };

  const { mutateAsync: updateCompany } = useMutation(
    (values: ICompanyForm) =>
      baseAxios.updateCompany(data?.InvoiceCompanyId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.company);
        queryClient.invalidateQueries(queryKeys.orders);
      },
    },
  );

  const { mutateAsync: updateAddress } = useMutation(
    (values: IAddressForm) =>
      baseAxios.updateAddress(data?.InvoiceCompanyId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.company);
        queryClient.invalidateQueries(queryKeys.orders);
      },
    },
  );

  const { mutateAsync: updateTax } = useMutation(
    (values: ITaxForm) => baseAxios.updateTax(data?.InvoiceCompanyId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.company);
        queryClient.invalidateQueries(queryKeys.orders);
      },
    },
  );

  const { mutateAsync: update } = useMutation(
    (values: ICompanyAPI) => baseAxios.update(values.InvoiceCompanyId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.company);
        queryClient.invalidateQueries(queryKeys.orders);
      },
    },
  );

  const { mutateAsync: uploadCertificate } = useMutation(
    ({ certificateFile, certificatePassword }: ICertificateAPI) =>
      baseAxios.uploadCertificate(certificateFile, certificatePassword),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.company),
    },
  );

  const { mutateAsync: deleteCertificate } = useMutation(
    () => baseAxios.deleteCertificate(data?.InvoiceCompanyId),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.company),
    },
  );

  const hasCompany = !!data?.InvoiceCompanyId;

  return (
    <CompanyContext.Provider
      value={{
        data,
        status,
        hasCompany,
        companyLogo,
        refetch,
        update,
        updateCompany,
        updateAddress,
        updateTax,
        uploadCertificate,
        uploadCompanyLogo,
        deleteCompanyLogo,
        deleteCertificate,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('useCompany must be used within an CompanyProvider');
  }

  return context;
}
