import { ITaxProfileForm, ITaxProfileAPI, IYesNoType } from '../interfaces';

class TaxProfileDAO {
  public taxToForm(profile?: ITaxProfileAPI): ITaxProfileForm {
    return {
      ...(profile ?? {}),
      Name: profile?.Name ?? 'Venda de Mercadorias',
      Description: profile?.Description ?? '',
      Natureza: profile?.Configurations.Natureza ?? 'Venda de Mercadorias',
      CodigoCFOP: profile?.Configurations.Item.ProductDefaultCFOP ?? 'x102',
      OrigemICMS: profile?.Configurations.Item.ProductDefaultOrigem ?? '0',
      CodigoICMS: profile?.Configurations.Tributos.ICMS.Operacao ?? '102',
      CodigoPIS: profile?.Configurations.Tributos.PIS.Operacao ?? '08',
      CodigoCOFINS: profile?.Configurations.Tributos.COFINS.Operacao ?? '08',
      DefaultFreightCode:
        profile?.Configurations.DefaultFreightCode.toString() ?? '0',
      OrderIdentifier:
        profile?.Configurations.Item.OrderIdentifier ?? 'marketplace',
      ProductIdentifier:
        profile?.Configurations.Item.ProductIdentifier ?? 'sku',
      RejectWithoutEAN:
        (profile?.Configurations.Item.RejectWithoutEAN.toString() as IYesNoType) ??
        '0',
      Presencial:
        (profile?.Configurations.Presencial.toString() as IYesNoType) ?? '0',
      ConsumidorFinal:
        (profile?.Configurations.ConsumidorFinal.toString() as IYesNoType) ??
        '1',
      TipoImpressao: profile?.Configurations.TipoImpressao.toString() ?? '1',
      Finalidade: profile?.Configurations.Finalidade.toString() ?? '1',
      NotasComplementaresDANFE:
        profile?.Configurations.NotasComplementaresDANFE ?? '',
      PermissionIds: profile?.Configurations.PermissionIds ?? '',
      CancellationDefaultReason:
        profile?.Configurations.CancellationDefaultReason ?? '',
    };
  }

  public taxToAPI(
    form: ITaxProfileForm,
    companyId: string,
    invoiceConfigurationId?: string,
  ): Partial<ITaxProfileAPI> {
    return {
      InvoiceConfigurationId: invoiceConfigurationId,
      CompanyId: companyId,
      Name: form.Name,
      Description: form.Description,
      Configurations: {
        Presencial: +form.Presencial,
        Natureza: form.Natureza,
        ConsumidorFinal: +form.ConsumidorFinal,
        DefaultFreightCode: +form.DefaultFreightCode,
        // Serie: 0,
        Finalidade: +form.Finalidade,
        TipoImpressao: +form.TipoImpressao,
        TipoEmissao: form.TipoImpressao,
        NotasComplementaresDANFE: form.NotasComplementaresDANFE,
        NotasComplementaresFISCO: '',
        CancellationDefaultReason: form.CancellationDefaultReason,
        PermissionIds: form.PermissionIds,
        Item: {
          // ProductName: '',
          // TagEANTributavel: '',
          RejectWithoutEAN: +form.RejectWithoutEAN,
          ProductIdentifier: form.ProductIdentifier,
          OrderIdentifier: form.OrderIdentifier,
          ProductDefaultCFOP: form.CodigoCFOP,
          ProductDefaultOrigem: form.OrigemICMS,
        },
        Tributos: {
          PIS: {
            Operacao: form.CodigoPIS,
          },
          COFINS: {
            Operacao: form.CodigoCOFINS,
          },
          ICMS: {
            Operacao: form.CodigoICMS,
          },
        },
      },
    };
  }
}

const taxProfileDAO = new TaxProfileDAO();

export default taxProfileDAO;
