// import { queryKeys } from './../data/queryKeys';
import { QueryClient } from 'react-query';
// import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
// import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

// 1 day
const cacheTime = 24 * 60 * 60 * 1000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime,
    },
  },
});

// const localStoragePersistor = createWebStoragePersistor({
//   storage: window.localStorage,
// });

// const doNotPersistQueries: QueryKey[] = [queryKeys.orders, queryKeys.nfes];

// persistQueryClient({
//   queryClient,
//   persistor: localStoragePersistor,
//   maxAge: cacheTime,
//   hydrateOptions: {},
//   dehydrateOptions: {
//     shouldDehydrateQuery: ({ queryKey }) => {
//       return !doNotPersistQueries.includes(queryKey);
//     },
//   },
// });

export default queryClient;
