import { z } from 'zod';

const taxProfileSchema = z.object({
  Name: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  Description: z.string().trim(),
  DefaultFreightCode: z.string(),
  Natureza: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CodigoCFOP: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  OrigemICMS: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CodigoICMS: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CodigoPIS: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CodigoCOFINS: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  OrderIdentifier: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  ProductIdentifier: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  RejectWithoutEAN: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  Presencial: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  ConsumidorFinal: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  TipoImpressao: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  Finalidade: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  NotasComplementaresDANFE: z.string().trim(),
  PermissionIds: z.string().trim(),
  CancellationDefaultReason: z.string().trim(),
});

export default taxProfileSchema;
