import { z } from 'zod';

const taxSchema = z.object({
  simples: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  serieNFe: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  regimeTributario: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  regimeTributarioEspecial: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  numeroInicial: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  ambiente: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
});

export default taxSchema;
