import React from 'react';
import { Text } from '@tiendanube/components';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';

interface OrderCellTotalProps {
  order: IOrderAPI;
}

function OrderCellTotal({ order }: OrderCellTotalProps) {
  return (
    <Text bold>
      {order.AmountTotal.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}
    </Text>
  );
}

export default OrderCellTotal;
