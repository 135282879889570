import React from 'react';
import { IOrderAPI } from '../../../../../../stratus/interfaces';
import {
  OrderCellActions,
  OrderCellCustomer,
  OrderCellDate,
  OrderCellId,
  OrderCellProducts,
  OrderCellStatus,
  OrderCellTotal,
} from './components';

export type OrderCellType =
  | 'id'
  | 'date'
  | 'customer'
  | 'products'
  | 'status'
  | 'total'
  | 'actions';

interface OrderCellProps {
  order: IOrderAPI;
  type: OrderCellType;
  issuingNfe: boolean;
  openModal: (order: IOrderAPI) => void;
}

function OrderCell({ order, type, issuingNfe, openModal }: OrderCellProps) {
  switch (type) {
    case 'id':
      return <OrderCellId order={order} />;
    case 'date':
      return <OrderCellDate order={order} />;
    case 'customer':
      return <OrderCellCustomer order={order} />;
    case 'total':
      return <OrderCellTotal order={order} />;
    case 'products':
      return <OrderCellProducts order={order} />;
    case 'status':
      return <OrderCellStatus order={order} openModal={openModal} />;
    case 'actions':
      return (
        <OrderCellActions
          order={order}
          openModal={openModal}
          issuingNfe={issuingNfe}
        />
      );
    default:
      return null;
  }
}

export default OrderCell;
