import { z } from 'zod';

const companySchema = z.object({
  RazaoSocial: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  NomeFantasia: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  Cnpj: z
    .string()
    .trim()
    .min(14, { message: 'Esse campo deve ter pelo menos 14 caracteres' }),
  InscrEstadual: z
    .string()
    .trim()
    .min(1, { message: 'Esse campo é obrigatório' }),
  InscrMunicipal: z.string().trim().optional(),
  EmailFiscal: z
    .string()
    .trim()
    .email()
    .min(1, { message: 'Esse campo é obrigatório' }),
  TelefoneFiscalDDD: z
    .string()
    .trim()
    .min(2, { message: 'Esse campo deve 2 caracteres' })
    .max(2, { message: 'Esse campo deve 2 caracteres' }),
  TelefoneFiscal: z
    .string()
    .trim()
    .min(8, { message: 'Esse campo é obrigatório' }),
});

export default companySchema;
