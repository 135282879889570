import { z } from 'zod';

const addressSchema = z.object({
  CEP: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  Street: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  Number: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  Complement: z.string().trim().optional(),
  Neighbourhood: z.string().trim().optional(),
  City: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  State: z
    .string()
    .trim()
    .min(2, { message: 'Esse campo deve ter 2 caracteres' })
    .max(2, { message: 'Esse campo deve ter 2 caracteres' }),
  CodigoIBGE: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
});

export default addressSchema;
