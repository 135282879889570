import axios, { AxiosInstance } from 'axios';
import { appConfigData } from '../data';
import {
  IAddressForm,
  ICompanyForm,
  IAPIResponse,
  ICompanyAPI,
  ITaxForm,
  ITaxProfileAPI,
  ITaxProfileForm,
  INfe,
  IStatisticsAPI,
  ICertificatePostResponse,
  IUserContract,
  INfeCreateResponse,
  INfeCreateForm,
} from '../interfaces';
import { companyMock, nfeMock, statsMock, taxProfileMock } from '../mocks';
import { delayUtil, loggerUtil } from '../utils';
import baseAuthAxios from './baseAuthAxios';
import companyDAO from './companyDAO';
import taxProfileDAO from './taxProfileDAO';

class BaseAxios {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: appConfigData.baseUrl,
      timeout: appConfigData.apiTimeout,
    });

    this.api.interceptors.request.use(
      (config) => baseAuthAxios.getInterceptorConfig(config),
      (error) => Promise.reject(error),
    );
  }

  public uploadCertificate = async (
    certificateFile: File,
    certificatePassword: string,
  ): Promise<ICompanyAPI> => {
    if (appConfigData.useMockData) {
      loggerUtil('certificateFile', certificateFile);
      loggerUtil('certificatePassword', certificatePassword);
      await delayUtil(1000);
      return companyMock;
    }

    const { data } = await this.api.postForm<ICertificatePostResponse>(
      'Configuration/Certificate',
      {
        certificateFile,
        certificatePassword,
      },
    );
    return data.Response;
  };

  public deleteCertificate = async (
    companyId?: string,
  ): Promise<IAPIResponse<boolean>> => {
    const { data } = await this.api.delete<IAPIResponse<boolean>>(
      `Configuration/Certificate/${companyId}`,
    );
    return data;
  };

  public getUserContracts = async () => {
    const { data } = await this.api.get<IAPIResponse<IUserContract[]>>(
      '/User/Contracts',
    );
    return data.Response[0];
  };

  public getCompany = async () => {
    if (appConfigData.useMockData) {
      return companyMock;
    }

    const { data } = await this.api.get<IAPIResponse<ICompanyAPI[]>>(
      '/Configuration/Company',
    );
    return data.Response[0];
  };

  public getCompanyLogo = async (companyId: string) => {
    // TODO: Check type
    const { data } = await this.api.get<any>(
      `/Configuration/Company/${companyId}/Logo`,
    );
    return data;
  };

  public uploadCompanyLogo = async (companyId: string, logoFile: File) => {
    // TODO: Check type
    const { data } = await this.api.postForm<any>(
      `/Configuration/Company/${companyId}/Logo`,
      { logoFile },
    );
    return data;
  };

  public deleteCompanyLogo = async (companyId: string) => {
    // TODO: Check type
    const { data } = await this.api.delete<any>(
      `/Configuration/Company/${companyId}/Logo`,
    );
    return data;
  };

  public getStatistics = async () => {
    if (appConfigData.useMockData) {
      return statsMock;
    }

    const { data } = await this.api.get<IAPIResponse<IStatisticsAPI>>('/Stats');
    return data.Response;
  };

  public getTaxProfiles = async () => {
    const { data } = await this.api.get<IAPIResponse<ITaxProfileAPI[]>>(
      '/Configuration',
    );
    return data.Response;
  };

  public getNfes = async (PageSize: number, Page: number) => {
    const { data } = await this.api.get<IAPIResponse<INfe[]>>(
      `/Nfe?page=${Page}&pageSize=${PageSize}`,
    );
    return data;
  };

  public getTempOrderFilter = async () => {
    const { data } = await this.api.get<string[]>(
      '/NoovFiscal/Orders/Filter/Get',
    );
    return data;
  };

  public deleteNfe = async (id: string) => {
    if (appConfigData.useMockData) {
      loggerUtil('id', id);
      await delayUtil(1000);
      return nfeMock;
    }

    const { data } = await this.api.delete<IAPIResponse<INfe>>(`/Nfe/${id}`);
    return data.Response;
  };

  public validateNFesOrders = async (ordersToValidate: INfeCreateForm) => {
    const { data } = await this.api.post<IAPIResponse<INfeCreateResponse>>(
      `/Nfe/Validate`,
      ordersToValidate,
    );
    return data.Response;
  };

  public issueNFesOrders = async (ordersToIssue: INfeCreateForm) => {
    const { data } = await this.api.post<IAPIResponse<INfeCreateResponse>>(
      `/Nfe/Create`,
      ordersToIssue,
    );
    return data.Response;
  };

  public updateTaxProfile = async (
    id: string,
    taxProfile: ITaxProfileForm,
    companyId: string,
  ) => {
    if (appConfigData.useMockData) {
      loggerUtil('taxProfile', taxProfile);
      await delayUtil(1000);
      return taxProfileMock;
    }

    const { data } = await this.api.put<IAPIResponse<ITaxProfileAPI>>(
      `/Configuration/${id}`,
      taxProfileDAO.taxToAPI(taxProfile, companyId, id),
    );
    return data.Response;
  };

  public createTaxProfile = async (
    taxProfile: ITaxProfileForm,
    companyId: string,
  ) => {
    if (appConfigData.useMockData) {
      loggerUtil('taxProfile', taxProfile);
      await delayUtil(1000);
      return taxProfileMock;
    }

    const { data } = await this.api.post<IAPIResponse<ITaxProfileAPI>>(
      `/Configuration`,
      taxProfileDAO.taxToAPI(taxProfile, companyId),
    );
    return data.Response;
  };

  public deleteTaxProfile = async (taxProfileId: string) => {
    if (appConfigData.useMockData) {
      loggerUtil('taxProfileId', taxProfileId);
      await delayUtil(1000);
      return taxProfileMock;
    }

    const { data } = await this.api.delete<IAPIResponse<ITaxProfileAPI>>(
      `/Configuration/${taxProfileId}`,
    );
    return data.Response;
  };

  public updateCompany = async (companyId?: string, values?: ICompanyForm) => {
    const company = await this.getCompany();
    const newCompany = companyDAO.companyToAPI(company, values);
    return this.update(companyId, newCompany);
  };

  public updateAddress = async (companyId?: string, values?: IAddressForm) => {
    const company = await this.getCompany();
    const newCompany = companyDAO.addressToAPI(company, values);
    return this.update(companyId, newCompany);
  };

  public updateTax = async (companyId?: string, values?: ITaxForm) => {
    const company = await this.getCompany();
    const newCompany = companyDAO.taxToAPI(company, values);
    return this.update(companyId, newCompany);
  };

  public update = async (companyId?: string, values?: ICompanyAPI) => {
    if (appConfigData.useMockData) {
      loggerUtil('companyId', companyId);
      loggerUtil('values', values);
      await delayUtil(1000);
      return companyMock;
    }

    const { data } = await this.api.put<IAPIResponse<ICompanyAPI>>(
      `/Configuration/Company/${companyId}`,
      values,
    );
    return data.Response;
  };
}

const baseAxios = new BaseAxios();

export default baseAxios;
