import { Text, DataTable } from '@tiendanube/components';

import { useState } from 'react';

import {
  useOrders,
  useNfe,
  useTax,
  useToast,
} from '../../../../../stratus/context';
import {
  INfeCreateForm,
  IOrderAPI,
  IOrderDataForm,
} from '../../../../../stratus/interfaces';
import { orderDAO } from '../../../../../stratus/services';
import { loggerUtil } from '../../../../../stratus/utils';
import { FiscalInfoModal } from '../../FiscalInfoModal';
import OrderCell, { OrderCellType } from './OrderCell/OrderCell';

const { Header, Cell, Row } = DataTable;

interface ColumnType {
  grow?: 0 | 1 | 2 | 3 | 4 | undefined;
  basis?: number | undefined;
  title: string;
  type: OrderCellType;
}

const columns: ColumnType[] = [
  {
    grow: 0,
    basis: 100,
    title: 'Venda',
    type: 'id',
  },
  {
    grow: 0,
    basis: 80,
    title: 'Data',
    type: 'date',
  },
  {
    grow: 1,
    basis: 100,
    title: 'Cliente',
    type: 'customer',
  },
  {
    grow: 0,
    basis: 130,
    title: 'Total',
    type: 'total',
  },
  {
    grow: 0,
    basis: 120,
    title: 'Produtos',
    type: 'products',
  },
  {
    grow: 0,
    basis: 160,
    title: 'Status da NFe',
    type: 'status',
  },
  {
    grow: 0,
    basis: 100,
    title: 'Ações',
    type: 'actions',
  },
];

function OrdersListDesktop() {
  const { data: orders, status, validateOrders } = useOrders();
  const { showApiError, showSuccess } = useToast();
  const { issueNFeMutation } = useNfe();
  const { data: taxProfiles } = useTax();

  const [issuingNfe, setIssuingNfe] = useState<string>();
  const [selectedOrder, setSelectedOrder] = useState<IOrderAPI>();

  const handleIssueNFe = async (order: IOrderAPI) => {
    const taxProfile = taxProfiles?.[0];

    if (!taxProfile) {
      // TODO: Show error
      return;
    }

    const orderToInvoice: IOrderDataForm = {
      Order: orderDAO.orderToOrderData(order),
      OrderId: order.OrderId,
    };

    const ordersToCreate: INfeCreateForm = {
      Configuration: taxProfile,
      OrdersToInvoice: [orderToInvoice],
    };

    setIssuingNfe(order.OrderId);

    try {
      await issueNFeMutation.mutateAsync(ordersToCreate);
      showSuccess('Sua nota está sendo gerada');
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Ocorreu um erro ao gerar sua nota, tente novamente mais tarde',
      );
    }

    setIssuingNfe(undefined);
  };

  const openModal = (order: IOrderAPI) => {
    setSelectedOrder(order);
  };

  const handleCloseModal = () => {
    if (selectedOrder) {
      validateOrders([selectedOrder]);
    }
    setSelectedOrder(undefined);
  };

  return (
    <DataTable>
      <Header>
        {columns.map((column) => (
          <Cell key={column.title} grow={column.grow} basis={column.basis}>
            <Text bold>{column.title}</Text>
          </Cell>
        ))}
      </Header>

      {status === 'loading' &&
        ['1', '2', '3', '4'].map((id) => (
          <Row id={id} key={id} align="center">
            {columns.map((column) => (
              <Cell key={column.title} grow={column.grow} basis={column.basis}>
                <Text.Skeleton />
              </Cell>
            ))}
          </Row>
        ))}

      {orders?.map((order) => (
        <Row id={order.OrderId} align="center" key={order.OrderId}>
          {columns.map((column) => (
            <Cell key={column.title} grow={column.grow} basis={column.basis}>
              <OrderCell
                key={column.type}
                type={column.type}
                order={order}
                openModal={openModal}
                issuingNfe={issuingNfe === order.OrderId}
              />
            </Cell>
          ))}
        </Row>
      ))}

      <FiscalInfoModal
        order={selectedOrder}
        onClose={handleCloseModal}
        onIssueNFe={handleIssueNFe}
      />
    </DataTable>
  );
}

export default OrdersListDesktop;
