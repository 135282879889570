import { connect, getSessionToken, iAmReady } from '@tiendanube/nexo/helpers';
import jwtDecode from 'jwt-decode';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { NSTokenPayload } from '../../interfaces';
import { nexoClient } from '../../services';
import { loggerUtil } from '../../utils';

type AuthenticationStatus = 'loading' | 'success' | 'error';

interface AuthenticationContextData {
  authenticationStatus: AuthenticationStatus;
  isAuthenticated: boolean | undefined;
  storeId: string;
  authenticate: () => void;
}

const AuthenticationContext = createContext<AuthenticationContextData>(
  {} as AuthenticationContextData,
);

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const [storeId, setStoreId] = useState('');
  const [authenticationStatus, setAuthenticationStatus] =
    useState<AuthenticationStatus>('loading');

  const isAuthenticated = authenticationStatus === 'success';

  const authenticate = useCallback(() => {
    setAuthenticationStatus('loading');

    connect(nexoClient)
      .then(async () => {
        iAmReady(nexoClient);
        const token = await getSessionToken(nexoClient);
        const decodedToken = jwtDecode<NSTokenPayload>(token);
        setStoreId(decodedToken.storeId);
        setAuthenticationStatus('success');
      })
      .catch((error) => {
        loggerUtil('[AuthenticationProvider:authenticate]: ', error);
        setAuthenticationStatus('error');
      });
  }, []);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  useEffect(() => {
    Sentry.setTag('StoreId', storeId);
  }, [storeId]);

  return (
    <AuthenticationContext.Provider
      value={{ isAuthenticated, authenticationStatus, storeId, authenticate }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication(): AuthenticationContextData {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      'useAuthentication must be used within an AuthenticationProvider',
    );
  }

  return context;
}
