import React from 'react';
import { Text } from '@tiendanube/components';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';
import { formatDateUtil } from '../../../../../../../stratus/utils';

interface OrderCellDateProps {
  order: IOrderAPI;
}

function OrderCellDate({ order }: OrderCellDateProps) {
  return <Text>{formatDateUtil(order.DateOrdered, 'dd MMM')}</Text>;
}

export default OrderCellDate;
