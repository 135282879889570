import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const printTypeOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: '0', label: 'Não gerar DANFE' },
  { value: '1', label: 'Retrato' },
  { value: '2', label: 'Paisagem' },
  { value: '3', label: 'Simplificado' },
];

export default printTypeOptionsConst;
