import { Button, Stack } from '@tiendanube/components';
import { CogIcon } from '@tiendanube/icons';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { nexoClient } from '../../stratus/services';
import { Page } from '../../stratus/components';

import { OrdersList, Statistics } from './components';
import { noovfiscalLogoBlack } from '../../stratus/assets';

function PageMain() {
  const { push } = useHistory();

  const handleGoToConfiguration = () => push('/configuration');

  useEffect(() => {
    navigateHeaderRemove(nexoClient);
  }, []);

  return (
    <Page
      header={
        <Stack justify="space-between">
          <Page.Header
            title={
              <img src={noovfiscalLogoBlack} className="nimbus--app-logo" />
            }
          />
          <Button
            appearance="default"
            onClick={handleGoToConfiguration}
            icon={CogIcon}
          >
            Configurações
          </Button>
        </Stack>
      }
    >
      <Stack column align="stretch">
        <OrdersList />

        <Statistics />
      </Stack>
    </Page>
  );
}

export default PageMain;
