import {
  Button,
  CalloutCard,
  Pagination,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { WalletIcon } from '@tiendanube/icons';
import { UndoIcon } from '@tiendanube/icons';
import { useHistory } from 'react-router-dom';
import { MessageCard } from '../../../../stratus/components';
import { useOrders } from '../../../../stratus/context';
import { useResponsive } from '../../../../stratus/hooks';

import NfeListDesktop from './OrdersListDesktop';
import NfeListMobile from './OrdersListMobile';

function OrdersList(): JSX.Element {
  const { push } = useHistory();
  const { isMobile } = useResponsive();
  const {
    data,
    status,
    page,
    pageTotal,
    totalItems,
    pageSize,
    setPage,
    refetch,
    openSalesPage,
  } = useOrders();

  const handleOpenNfeList = () => {
    push('/nfes');
  };

  const Content = () => {
    if (status === 'success' || status === 'loading') {
      if (status === 'success' && !data?.length) {
        return (
          <MessageCard
            title="Nenhum pedido encontrado"
            message="Você ainda não possui nenhum pedido"
          />
        );
      }

      return (
        <>
          {isMobile ? <NfeListMobile /> : <NfeListDesktop />}
          <Stack justify="space-between" align="flex-start">
            <Stack.Item>
              {!isMobile && (
                <Text>{`Mostrando ${Math.min(
                  pageSize,
                  totalItems,
                )} vendas de ${totalItems}`}</Text>
              )}
            </Stack.Item>
            <Stack.Item>
              <Pagination
                pageTotal={pageTotal}
                pageSelected={page}
                onPageSelect={(page) => setPage(page)}
              />
            </Stack.Item>
          </Stack>
        </>
      );
    }

    if (status === 'error') {
      return (
        <MessageCard
          title="Erro ao buscar seus pedidos"
          message="Ocorreu um erro e não conseguimos buscar seus pedidos. Tente novamente clicando no botão 'Atualizar lista' e se o erro persistir entre em contato conosco"
        />
      );
    }

    return null;
  };

  return (
    <>
      <Stack>
        <CalloutCard
          icon={WalletIcon}
          appearance="success"
          title="Ative o Noov Fiscal – Grátis por 14 dias"
          subtitle="Você ainda não tem uma assinatura ativa no Noov Fiscal. Clique aqui para escolher um plano."
          onClick={openSalesPage}
        />
      </Stack>

      <Stack justify="space-between">
        <Title type="h3">Últimos pedidos</Title>
        <Stack spacing="tight">
          <Button icon={UndoIcon} onClick={() => refetch()}>
            Atualizar lista
          </Button>
          <Button onClick={() => handleOpenNfeList()} appearance="primary">
            Lista de NFe
          </Button>
        </Stack>
      </Stack>
      <Content />
    </>
  );
}

export default OrdersList;
