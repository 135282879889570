import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const icmsOptionsConst: InterfaceSelectOptionGroup[] = [
  {
    value: '101',
    label: '101 - Tributação pelo Simples com Permissão de Crédito',
  },
  {
    value: '102',
    label: '102 - Tributação pelo Simples sem Permissão de Crédito',
  },
  {
    value: '103',
    label: '103 - Isenção do ICMS no Simples para receita bruta',
  },
  {
    value: '201',
    label:
      '201 - Simples Nacional com Permissão de Crédito e ICMS por Substituição Tributária',
  },
  {
    value: '202',
    label:
      '202 - Simples Nacional sem Permissão de crédito e com cobrança de ICMS por substituição tributária',
  },
  {
    value: '203',
    label:
      '203 - Isenção do ICMS no Simples para faixa da Receita Bruta e com cobrança de ICMS por substituição tributária',
  },
  { value: '300', label: '300 - Imunidade' },
  { value: '400', label: '400 - Não tributado pelo Simples' },
  { value: '500', label: '500 - ICMS cobrado anteriormente por substituição' },
  { value: '900', label: '900 - Outros' },
  // { value: "00", label: "00 - Tributada integralmente" },
  // { value: "10", label: "10 - Tributada e com cobrança do ICMS por substituição tributária" },
  // { value: "20", label: "20 - Com redução de base de cálculo" },
  // { value: "30", label: "30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária" },
  // { value: "40", label: "40 - Isenta" },
  // { value: "41", label: "41 - Não tributada" },
  // { value: "50", label: "50 - Suspensão" },
  // { value: "51", label: "51 - Diferimento" },
  // { value: "60", label: "60 - ICMS cobrado anteriormente por substituição tributária" },
  // { value: "70", label: "70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária" },
  // { value: "90", label: "90 - Outras" }
];

export default icmsOptionsConst;
