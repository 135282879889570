import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const finalidadeOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: '1', label: 'NF-e Normal' },
  { value: '2', label: 'NF-e Complementar' },
  { value: '3', label: 'NF-e de Ajuste' },
  { value: '4', label: 'NF-e de Devolução de Mercadoria' },
];

export default finalidadeOptionsConst;
