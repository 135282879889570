import { useState } from 'react';
import { Card, Stack } from '@tiendanube/components';
import UploadCertificate from './components/UploadCertificate/UploadCertificate';
import { DigitalCertificateData } from './components';
import { useCompany, useToast } from '../../../../stratus/context';
import { loggerUtil } from '../../../../stratus/utils';

function DigitalCertificateCard() {
  const { showApiError, showSuccess } = useToast();
  const { data: company, deleteCertificate } = useCompany();
  const [showCertificate, setShowCertificate] = useState(true);

  const isCertificateVisible = company?.CertificateId && showCertificate;

  const handleClose = () => {
    setShowCertificate(true);
  };

  const handleRemoveCertificate = async () => {
    try {
      await deleteCertificate();
      setShowCertificate(false);
      showSuccess('Certificado removido com sucesso');
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Falha ao remover o certificado digital, tente novamente mais tarde',
      );
    }
  };

  return (
    <Card title="Certificado Digital" isCollapsible>
      <Stack column align="stretch">
        {isCertificateVisible ? (
          <DigitalCertificateData
            onChangeClick={() => setShowCertificate(false)}
            onRemoveClick={handleRemoveCertificate}
          />
        ) : (
          <UploadCertificate onClose={handleClose} />
        )}
      </Stack>
    </Card>
  );
}

export default DigitalCertificateCard;
