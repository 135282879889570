import React from 'react';
import { Redirect, RouteProps, Route } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
  isAllowed?: boolean;
  redirect?: string;
}

function ProtectedRoute({
  children,
  isAllowed = true,
  redirect = '/',
  ...props
}: ProtectedRouteProps) {
  return (
    <Route {...props}>
      {isAllowed ? children : <Redirect to={redirect} />}
    </Route>
  );
}

export default ProtectedRoute;
