import axios, { AxiosInstance } from 'axios';
import { appConfigData } from '../data';
import {
  IAPIResponse,
  IOrderAPI,
  INSMetafieldAPI,
  IUserConnectionAPI,
  IOrderProductFiscalInfoForm,
} from '../interfaces';
import baseAuthAxios from './baseAuthAxios';
import baseAxios from './baseAxios';

class BaseOrderAxios {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: appConfigData.baseOrderUrl,
      timeout: appConfigData.apiTimeout,
    });

    this.api.interceptors.request.use(
      (config) => baseAuthAxios.getInterceptorConfig(config),
      (error) => Promise.reject(error),
    );
  }

  public getUserConnections = async () => {
    const { data } = await this.api.get<IAPIResponse<IUserConnectionAPI[]>>(
      '/Connection',
    );

    return data.Response[0];
  };

  public patchProductFiscalInfo = async (
    connectionId: string,
    productId: string,
    productChildId: string,
    values: IOrderProductFiscalInfoForm,
  ) => {
    // TODO: Fix response type
    const { data } = await this.api.patch<any>(
      `/Connection/${connectionId}/Products/${productId}`,
      {
        StoreId: productId,
        Skus: [
          {
            StoreChildId: productChildId,
            Codes: values,
          },
        ],
      },
    );

    return data;
  };

  public getNSOrders = async (
    PageSize: number,
    Page: number,
    connectionId: string,
  ) => {
    const orderTempFilter = await baseAxios.getTempOrderFilter();

    const extraParams = {
      CustomFilter: 'LoadProductsFiscalInfo:1',
      Status: 'APPROVED',
      OrderIds: orderTempFilter.join(),
    };

    const { data } = await this.api.get<IAPIResponse<IOrderAPI[]>>(
      `/Connection/${connectionId}/Orders`,
      {
        params: { PageSize, Page, ...extraParams },
      },
    );

    return data;
  };

  public getProductFiscalInfo = async (
    productId: number,
    connectionId: string,
  ): Promise<INSMetafieldAPI[]> => {
    const { data } = await this.api.get<IAPIResponse<string>>(
      `/Connection/${connectionId}/Command`,
      {
        data: {
          command: 'Metafields',
          parameters: `GET Product ${productId}`,
        },
      },
    );

    //Exemplo de retorno, um string dentro do Response: [{\"key\":\"CST\",\"value\":\"08\",\"owner_id\":\"126498347\",\"namespace\":\"NFe\"}]
    //Os campos key são os mesmos comentados no serviço orderDAO... estes nomes são usados tanto para ler, como para escrever utilizando a API abaixo setProductFiscalInfo
    const productWithFields = JSON.parse(data.Response) as INSMetafieldAPI[];

    return productWithFields;
  };

  public setProductFiscalInfo = async (
    productId: number,
    fieldName: string,
    fieldValue: string,
    connectionId: string,
  ) => {
    const { data } = await this.api.get<IAPIResponse<string>>(
      `/Connection/${connectionId}/Command`,
      {
        data: {
          command: 'Metafields',
          parameters: `SET Product ${productId} NFe ${fieldName} ${fieldValue}`,
        },
      },
    );

    return data.Response;
  };
}

const baseOrderAxios = new BaseOrderAxios();

export default baseOrderAxios;
