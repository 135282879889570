import React, { useEffect, useState } from 'react';
import {
  IOrderProductFiscalInfoForm,
  InvoiceRequestResultError,
  ItemInfo,
} from '../../../../stratus/interfaces';
import {
  CalloutCard,
  Card,
  Input,
  InterfaceNameValue,
  Select,
  Stack,
  Text,
  Link,
  Button,
} from '@tiendanube/components';
import { CloseIcon } from '@tiendanube/icons';
import { errorFormSchema } from '../../../../stratus/schemas';
import { useOrders } from '../../../../stratus/context';
import { loggerUtil } from '../../../../stratus/utils';
import {
  cfopOptionsConst,
  csosnOptionsConst,
  cstOptionsConst,
  originOptionsConst,
} from '../../../../stratus/constants';

interface TempMessage {
  appearance: 'success' | 'danger';
  message: string;
  duration: number;
}

interface ProductFiscalInfoFormProps {
  product: ItemInfo;
  error: InvoiceRequestResultError | undefined;
}

function ProductFiscalInfoForm({ product, error }: ProductFiscalInfoFormProps) {
  const { patchProductFiscalInfo } = useOrders();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IOrderProductFiscalInfoForm>({
    CFOP: product.FiscalData.CFOP || 'x102',
    CSOSN: product.FiscalData.CSOSN || '102',
    CST: product.FiscalData.CST || '08',
    EAN: product.FiscalData.EAN || '',
    ICMSOrigem: product.FiscalData.ICMSOrigem || '0',
    NCM: product.FiscalData.NCM || '',
    UNIDADE: product.FiscalData.UNIDADE || 'UN',
  });
  const fiscalData = product.FiscalData;
  const [isSuccess, setSuccess] = useState(
    !!fiscalData.CFOP &&
      !!fiscalData.CSOSN &&
      !!fiscalData.CST &&
      !!fiscalData.EAN &&
      !!fiscalData.ICMSOrigem &&
      !!fiscalData.NCM &&
      !!fiscalData.UNIDADE,
  );
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [tempMessage, setTempMessage] = useState<TempMessage>();

  useEffect(() => {
    if (tempMessage) {
      const timeout = setTimeout(() => {
        setTempMessage(undefined);
      }, tempMessage.duration);
      return () => clearTimeout(timeout);
    }
  }, [tempMessage]);

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await errorFormSchema.safeParseAsync(data);
      if (parsedData.success) {
        await patchProductFiscalInfo(
          product.ProductId,
          product.ProductChildId,
          data,
        );
        setSuccess(true);
        setTempMessage({
          appearance: 'success',
          message: 'Dados salvos com sucesso',
          duration: 3000,
        });
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        setTempMessage({
          appearance: 'danger',
          message: 'Preencha os campos obrigatórios',
          duration: 3000,
        });
      }
    } catch (error) {
      loggerUtil(error);
      setTempMessage({
        appearance: 'danger',
        message: 'Ocorreu um erro ao salvar os dados',
        duration: 3000,
      });
    }

    setLoading(false);
  };

  return (
    <Card
      title={product.Name}
      headerLabel={{
        appearance: isSuccess ? 'success' : 'danger',
        id: product.Sku,
        label: isSuccess ? 'Ok' : 'Clique para corrigir',
      }}
      key={product.ProductChildId}
      isCollapsible
    >
      <Stack column align="stretch">
        {error && (
          <CalloutCard
            icon={CloseIcon}
            appearance="danger"
            title="Erro de dados"
            subtitle={error.Error}
            textLink={{}}
          />
        )}

        <Select
          name="CST"
          label="CST"
          placeholder="Selecione uma opção"
          options={cstOptionsConst}
          onChange={handleChange}
          value={data.CST}
        />

        <Select
          name="CSOSN"
          label="CSOSN"
          placeholder="Selecione uma opção"
          options={csosnOptionsConst}
          onChange={handleChange}
          value={data.CSOSN}
        />

        <Select
          name="CFOP"
          label="CFOP"
          placeholder="Selecione uma opção"
          options={cfopOptionsConst}
          onChange={handleChange}
          value={data.CFOP}
        />

        <Select
          name="ICMSOrigem"
          label="Origem ICMS"
          placeholder="Selecione uma opção"
          options={originOptionsConst}
          onChange={handleChange}
          value={data.ICMSOrigem}
        />

        <Stack.Item>
          <Input
            name="NCM"
            label="NCM"
            placeholder="Ex: 0000.00.00"
            value={data.NCM}
            onChange={handleChange}
            disabled={isLoading}
            appearance={errors['NCM'] ? 'danger' : 'default'}
            helpText={errors['NCM']}
          />
          <Text appearance="light" size="caption">
            Não sabe o NCM?{' '}
            <Link
              size="small"
              appearance="primary"
              target="_blank"
              href="https://cosmos.bluesoft.com.br/"
            >
              Clique aqui
            </Link>{' '}
            para buscar
          </Text>
        </Stack.Item>

        <Input
          name="UNIDADE"
          label="Unidade"
          placeholder="Ex: cx"
          value={data.UNIDADE}
          onChange={handleChange}
          disabled={isLoading}
          appearance={errors['UNIDADE'] ? 'danger' : 'default'}
          helpText={errors['UNIDADE']}
        />

        <Stack.Item>
          <Input
            name="EAN"
            label="EAN (Código de barras)"
            placeholder="Ex: 0000000000000"
            value={data.EAN}
            onChange={handleChange}
            disabled={isLoading}
            appearance={errors['EAN'] ? 'danger' : 'default'}
            helpText={errors['EAN']}
          />
          <Text appearance="light" size="caption">
            Não sabe o EAN?{' '}
            <Link
              size="small"
              appearance="primary"
              target="_blank"
              href="https://cosmos.bluesoft.com.br/"
            >
              Clique aqui
            </Link>{' '}
            para buscar
          </Text>
        </Stack.Item>
        <Stack spacing="base" justify="flex-end">
          {tempMessage && (
            <Stack.Item>
              <Text appearance={tempMessage.appearance}>
                {tempMessage.message}
              </Text>
            </Stack.Item>
          )}
          <Stack.Item>
            <Button
              onClick={handleSave}
              appearance="primary"
              spinner={isLoading}
            >
              Salvar
            </Button>
          </Stack.Item>
        </Stack>
      </Stack>
    </Card>
  );
}

export default ProductFiscalInfoForm;
