import { Label, Stack, Thumbnail } from '@tiendanube/components';
import { useCompany, useToast } from '../../context';
import { appConfigData } from '../../data';
import { TrashIcon } from '@tiendanube/icons';
import { loggerUtil } from '../../utils';
import { useState } from 'react';

interface CompanyLogoProps {
  disableLogoUpload?: boolean;
}

function CompanyLogo({ disableLogoUpload = true }: CompanyLogoProps) {
  const { companyLogo, uploadCompanyLogo, deleteCompanyLogo } = useCompany();
  const { showError, showApiError, showSuccess } = useToast();

  const [isLoading, setLoading] = useState(false);

  const handleSelectFile = async (files: File[]) => {
    const [file] = files;

    const allowedTypes = appConfigData.imageFileType.split(',');
    if (!allowedTypes.includes(file.type)) {
      showError('Tipo de arquivo inválido, favor usar .png ou .jpg');
      return;
    }

    if (file.size >= appConfigData.imageFileSizeLimit) {
      showError(
        'Tamanho do arquivo inválido, favor usar arquivo menor que 2 mb',
      );
      return;
    }

    setLoading(true);
    try {
      await uploadCompanyLogo(file);
      showSuccess('Logo enviada com sucesso');
    } catch (error) {
      loggerUtil(error);
      showApiError(error, 'Erro ao enviar logo, tente novamente mais tarde');
    }
    setLoading(false);
  };

  const handleRemoveLogo = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    try {
      await deleteCompanyLogo();
      showSuccess('Logo removida com sucesso');
    } catch (error) {
      loggerUtil(error);
      showApiError(error, 'Erro ao remover logo, tente novamente mais tarde');
    }
    setLoading(false);
  };

  if (companyLogo) {
    return (
      <Stack column>
        <Thumbnail
          src={companyLogo}
          aspectRatio="2-1"
          width="200px"
          altText="Logo da empresa"
          loading={isLoading}
        />

        <Label
          id="remove"
          appearance="danger"
          label="Remover logo"
          icon={TrashIcon}
          onClick={handleRemoveLogo}
        />
      </Stack>
    );
  }

  return (
    <Thumbnail.File
      multiple={false}
      aspectRatio="2-1"
      ariaLabel="Logo da empresa"
      accept={appConfigData.imageFileType}
      onChange={handleSelectFile}
      width="200px"
      helperText="Logo da empresa"
      disabled={disableLogoUpload || isLoading}
    />
  );
}

export default CompanyLogo;
