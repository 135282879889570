import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const productIdentifierOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: 'sku', label: 'Sku' },
  { value: 'erp', label: 'Id do ERP' },
  { value: 'hub', label: 'Id do HUB' },
  { value: 'ean', label: 'GTIN/EAN' },
];

export default productIdentifierOptionsConst;
