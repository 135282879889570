import React from 'react';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';
import { Label, Spinner } from '@tiendanube/components';
import { useOrders } from '../../../../../../../stratus/context';
import {
  CheckIcon,
  CloseIcon,
  ExclamationTriangleIcon,
} from '@tiendanube/icons';
import { LabelStatus } from '../../../../../../../stratus/components';

interface OrderCellStatusProps {
  order: IOrderAPI;
  openModal: (order: IOrderAPI) => void;
}

function OrderCellStatus({ order, openModal }: OrderCellStatusProps) {
  const { nfeValidations, loadingNfeValidationIds } = useOrders();

  const isLoadingNfeValidation = loadingNfeValidationIds.includes(
    order.OrderId,
  );

  if (isLoadingNfeValidation) {
    return <Spinner size="medium" appearance="primary" />;
  }

  const orderValidation = nfeValidations?.find(
    (validation) => validation.OrderId === order.OrderId,
  );

  if (!orderValidation) {
    return null;
  }

  if (orderValidation.Errors.length) {
    if (orderValidation.Status === 'REJECTED') {
      return (
        <Label
          id="id"
          label="NFe rejeitada"
          icon={ExclamationTriangleIcon}
          appearance="warning"
          onClick={() => openModal(order)}
        />
      );
    } else {
      return (
        <Label
          id="id"
          label="Erro de validação"
          icon={CloseIcon}
          appearance="danger"
          onClick={() => openModal(order)}
        />
      );
    }
  }

  if (orderValidation.Status === 'READY_TO_ISSUE') {
    return (
      <Label
        id="id"
        label="Pronta p/ emitir"
        icon={CheckIcon}
        appearance="primary"
        onClick={() => openModal(order)}
      />
    );
  }

  return <LabelStatus status={orderValidation.Status} />;
}

export default OrderCellStatus;
