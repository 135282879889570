import {
  Button,
  Card,
  InterfaceNameValue,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { ChevronLeftIcon } from '@tiendanube/icons';
import { useMemo, useState } from 'react';
import { TaxProfileForm } from '../../../../stratus/components';
import { useToast } from '../../../../stratus/context';
import { ICompanyAPI, ITaxProfileForm } from '../../../../stratus/interfaces';
import { taxProfileSchema } from '../../../../stratus/schemas';
import { baseAxios, taxProfileDAO } from '../../../../stratus/services';
import { loggerUtil } from '../../../../stratus/utils';

interface OnboardingView3Props {
  onNext: () => void;
  onPrev: () => void;
  company?: ICompanyAPI;
}

function OnboardingView3({ onNext, onPrev, company }: OnboardingView3Props) {
  const { showApiError, showError } = useToast();

  const initialData = useMemo<ITaxProfileForm>(
    () => taxProfileDAO.taxToForm(),
    [],
  );

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await taxProfileSchema.safeParseAsync(data);
      if (parsedData.success) {
        await baseAxios.createTaxProfile(
          parsedData.data as ITaxProfileForm,
          company?.InvoiceCompanyId ?? '',
        );
        onNext();
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        showError('Erro ao salvar os dados, confira os campos novamente');
        setLoading(false);
      }
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Erro ao criar perfil fiscal, tente novamente mais tarde',
      );
      setLoading(false);
    }
  };

  return (
    <Stack column spacing="tight">
      <Stack.Item fill>
        <Stack column spacing="none">
          <Title type="h6">3 / 3</Title>
          <Title type="h1">Perfil Fiscal NFe</Title>
        </Stack>
      </Stack.Item>

      <Text>Crie o seu primeiro perfil para emissão das suas NFe</Text>

      <div className="nimbus--full-width">
        <Card title="Perfil Fiscal" isCollapsible={false}>
          <TaxProfileForm
            isLoading={isLoading}
            taxProfile={initialData}
            onChange={handleChange}
            errors={errors}
            showAdvancedConfig={false}
          />
        </Card>
      </div>

      <Stack.Item fill>
        <Stack spacing="tight">
          <Button
            appearance="default"
            onClick={onPrev}
            icon={ChevronLeftIcon}
          />
          <Button
            spinner={isLoading}
            appearance="primary"
            onClick={handleSubmit}
          >
            Finalizar
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default OnboardingView3;
