import { createContext, useContext, useEffect } from 'react';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import * as Sentry from '@sentry/react';
import { queryKeys } from '../../data';
import { IAPIResponse, ISignupForm, IUserAPI } from '../../interfaces';
import { baseUserAxios } from '../../services';
import { useAuthentication } from '../AuthenticationContext';

interface UserContextData {
  status: 'idle' | 'error' | 'loading' | 'success';
  hasUser: boolean;
  data: IUserAPI | undefined;
  refetch: () => void;
  signup: UseMutateAsyncFunction<
    IAPIResponse<any>,
    unknown,
    ISignupForm,
    unknown
  >;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const queryClient = useQueryClient();
  const { isAuthenticated, storeId } = useAuthentication();

  const { data, status, refetch } = useQuery(
    [queryKeys.user, storeId],
    baseUserAxios.getUser,
    { enabled: isAuthenticated },
  );

  const { mutateAsync: signup } = useMutation(
    (values: ISignupForm) => baseUserAxios.signup(values),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.user),
    },
  );

  useEffect(() => {
    if (data) {
      Sentry.setUser({ email: data.Email, id: data.UserId });
    } else {
      Sentry.setUser(null);
    }
  }, [data]);

  const hasUser = !!data?.UserId;

  return (
    <UserContext.Provider
      value={{
        status,
        data,
        hasUser,
        refetch,
        signup,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): UserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
}
