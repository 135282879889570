import { Spinner } from '@tiendanube/components';
import React from 'react';

function LoadingPage() {
  return (
    <div className="nimbus--full-screen-center-container">
      <Spinner size="large" />
    </div>
  );
}

export default LoadingPage;
