import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Input,
  InterfaceNameValue,
  Link,
  Stack,
  Text,
} from '@tiendanube/components';
import { SearchIcon } from '@tiendanube/icons';
import { CancelAndSaveButtons } from '..';
import { IAddressForm, ICompanyAPI } from '../../interfaces';
import { baseExternalAxios, companyDAO } from '../../services';
import { addressSchema } from '../../schemas';
import { loggerUtil } from '../../utils';
import { useToast } from '../../context';

interface AddressCardProps {
  defaultErrors?: Record<string, string>;
  isCollapsible?: boolean;
  isOpenByDefault?: boolean;
  company?: ICompanyAPI;
  updateAddress?: (address: IAddressForm) => Promise<ICompanyAPI>;
  onChange?: (data: InterfaceNameValue) => void;
}

function AddressCard({
  defaultErrors,
  isCollapsible = true,
  isOpenByDefault,
  company,
  onChange,
  updateAddress,
}: AddressCardProps) {
  const { showApiError, showError, showSuccess } = useToast();

  const initialValues = useMemo<IAddressForm>(
    () => companyDAO.addressToForm(company),
    [company],
  );

  const [errors, setErrors] = useState<Record<string, string>>(
    defaultErrors ?? {},
  );
  const [isLoading, setLoading] = useState(false);
  const [isLoadingCEP, setLoadingCEP] = useState(false);
  const [data, setData] = useState(initialValues);

  useEffect(() => {
    if (defaultErrors) {
      setErrors(defaultErrors);
    }
  }, [defaultErrors]);

  const handleSave = async () => {
    setLoading(true);
    setErrors({});

    try {
      const parsedData = await addressSchema.safeParseAsync(data);
      if (parsedData.success) {
        await updateAddress?.(parsedData.data);
        showSuccess('Dados atualizados com sucesso');
      } else {
        const newErrors: Record<string, string> = {};
        parsedData.error.errors.forEach((error) => {
          error.path.forEach((path) => {
            newErrors[path] = error.message;
          });
        });
        setErrors(newErrors);
        showError('Erro ao salvar os dados, confira os campos novamente');
      }
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Falha ao atualizar o endereço da empresa, tente novamente mais tarde',
      );
    }

    setLoading(false);
  };

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    setData({ ...data, [name]: value });
    onChange?.({ name, value });
  };

  const handleReset = () => {
    setData(initialValues);
  };

  const handleFillAddress = async () => {
    setLoadingCEP(true);

    try {
      const cepData = await baseExternalAxios.getCEP(data.CEP);
      const ibgeCode = await baseExternalAxios.getIBGECode(
        cepData.state,
        cepData.city,
      );

      setData({
        ...data,
        City: cepData.city,
        Neighbourhood: cepData.neighborhood,
        State: cepData.state,
        Street: cepData.street,
        Complement: '',
        Number: '',
        CodigoIBGE: ibgeCode ?? '',
      });
    } catch (error) {
      loggerUtil(error);
      showError('Erro ao buscar CEP');
    }
    setLoadingCEP(false);
  };

  return (
    <Card
      title="Endereço"
      isCollapsible={isCollapsible}
      isOpenByDefault={isOpenByDefault}
    >
      <Stack column align="stretch">
        <Stack align="flex-end">
          <Stack.Item fill>
            <Input
              required
              name="CEP"
              label="CEP"
              placeholder="CEP"
              value={data.CEP}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['CEP'] ? 'danger' : 'default'}
              helpText={errors['CEP']}
            />
          </Stack.Item>
          <Button
            spinner={isLoadingCEP}
            onClick={handleFillAddress}
            icon={SearchIcon}
            disabled={isLoading}
          >
            Completar endereço
          </Button>
        </Stack>

        <Stack align="flex-end">
          <Stack.Item fill>
            <Input
              required
              name="Street"
              label="Rua"
              placeholder="Rua"
              value={data.Street}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['Street'] ? 'danger' : 'default'}
              helpText={errors['Street']}
            />
          </Stack.Item>
          <Stack.Item>
            <Input
              required
              name="Number"
              label="Número"
              placeholder="Número"
              value={data.Number}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['Number'] ? 'danger' : 'default'}
              helpText={errors['Number']}
            />
          </Stack.Item>
        </Stack>

        <Stack align="flex-end">
          <Stack.Item fill>
            <Input
              name="Complement"
              label="Complemento"
              placeholder="Complemento"
              value={data.Complement}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['Complement'] ? 'danger' : 'default'}
              helpText={errors['Complement']}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Input
              name="Neighbourhood"
              label="Bairro"
              placeholder="Bairro"
              value={data.Neighbourhood}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['Neighbourhood'] ? 'danger' : 'default'}
              helpText={errors['Neighbourhood']}
            />
          </Stack.Item>
        </Stack>

        <Stack align="flex-end">
          <Stack.Item fill>
            <Input
              required
              name="City"
              label="Cidade"
              placeholder="Cidade"
              value={data.City}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['City'] ? 'danger' : 'default'}
              helpText={errors['City']}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Input
              required
              name="State"
              label="Estado"
              placeholder="Estado"
              maxLength={2}
              value={data.State}
              onChange={handleChange}
              disabled={isLoading}
              appearance={errors['State'] ? 'danger' : 'default'}
              helpText={errors['State']}
            />
          </Stack.Item>
        </Stack>
        <Stack.Item fill>
          <Input
            required
            name="CodigoIBGE"
            label="Código IBGE"
            placeholder="Código IBGE"
            maxLength={10}
            value={data.CodigoIBGE}
            onChange={handleChange}
            disabled={isLoading}
            appearance={errors['CodigoIBGE'] ? 'danger' : 'default'}
            helpText={errors['CodigoIBGE']}
          />
          <Text appearance="light" size="caption">
            Não sabe o Código IBGE?{' '}
            <Link
              size="small"
              appearance="primary"
              target="_blank"
              href="https://ibge.gov.br/explica/codigos-dos-municipios.php"
            >
              Clique aqui
            </Link>{' '}
            para buscar
          </Text>
        </Stack.Item>

        {!!updateAddress && (
          <CancelAndSaveButtons
            saveText="Salvar"
            onCancel={handleReset}
            onSave={handleSave}
            cancelText="Cancelar"
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        )}
      </Stack>
    </Card>
  );
}

export default AddressCard;
