import {
  Button,
  InterfaceNameValue,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { ICompanyAPI, ITaxForm } from '../../../../stratus/interfaces';
import { ChevronLeftIcon } from '@tiendanube/icons';
import {
  AddressCard,
  CompanyCard,
  TaxCard,
} from '../../../../stratus/components';
import { useState } from 'react';
import { baseAxios, companyDAO } from '../../../../stratus/services';
import {
  addressSchema,
  companySchema,
  taxSchema,
} from '../../../../stratus/schemas';

import { loggerUtil } from '../../../../stratus/utils';
import { useToast } from '../../../../stratus/context';

interface OnboardingView2Props {
  onNext: () => void;
  onPrev: () => void;
  company: ICompanyAPI;
}

function OnboardingView2({ onNext, onPrev, company }: OnboardingView2Props) {
  const { showError } = useToast();

  const [companyForm, setCompanyForm] = useState(
    companyDAO.companyToForm(company),
  );
  const [addressForm, setAddressForm] = useState(
    companyDAO.addressToForm(company),
  );
  const [taxForm, setTaxForm] = useState(companyDAO.taxToForm(company));
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setLoading] = useState(false);

  const handleChangeCompany = ({ name, value }: InterfaceNameValue) => {
    setCompanyForm({ ...companyForm, [name]: value });
  };

  const handleChangeAddress = ({ name, value }: InterfaceNameValue) => {
    setAddressForm({ ...addressForm, [name]: value });
  };

  const handleChangeTax = ({ name, value }: InterfaceNameValue) => {
    setTaxForm({ ...taxForm, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const parsedCompany = await companySchema.safeParseAsync(companyForm);
      const parsedAddress = await addressSchema.safeParseAsync(addressForm);
      const parsedTax = await taxSchema.safeParseAsync(taxForm);

      if (parsedCompany.success && parsedAddress.success && parsedTax.success) {
        const companyWithAddress = companyDAO.addressToAPI(
          company,
          parsedAddress.data,
        );
        const companyWithTax = companyDAO.taxToAPI(
          companyWithAddress,
          parsedTax.data as ITaxForm,
        );

        const companyComplete = companyDAO.companyToAPI(
          companyWithTax,
          parsedCompany.data,
        );

        await baseAxios.update(
          companyComplete.InvoiceCompanyId,
          companyComplete,
        );
        onNext();
      } else {
        const newErrors: Record<string, string> = {};
        if (!parsedCompany.success) {
          parsedCompany.error.errors.forEach((error) => {
            error.path.forEach((path) => (newErrors[path] = error.message));
          });
        }
        if (!parsedAddress.success) {
          parsedAddress.error.errors.forEach((error) => {
            error.path.forEach((path) => (newErrors[path] = error.message));
          });
        }
        if (!parsedTax.success) {
          parsedTax.error.errors.forEach((error) => {
            error.path.forEach((path) => (newErrors[path] = error.message));
          });
        }
        showError('Erro ao salvar os dados, confira os campos novamente');
        setLoading(false);
        setErrors(newErrors);
      }
    } catch (error) {
      loggerUtil(error);
      showError('Erro ao salvar os dados, tente novamente mais tarde');
      setLoading(false);
    }
  };

  return (
    <Stack column spacing="base">
      <Stack.Item fill>
        <Stack column spacing="none">
          <Title type="h6">2 / 3</Title>
          <Title type="h1">Empresa</Title>
        </Stack>
      </Stack.Item>

      <Text>Confirme aqui os dados da sua empresa</Text>

      <div className="nimbus--full-width">
        <CompanyCard
          disableLogoUpload
          defaultErrors={errors}
          isCollapsible={false}
          company={company}
          onChange={handleChangeCompany}
        />

        <AddressCard
          defaultErrors={errors}
          isCollapsible={false}
          company={company}
          onChange={handleChangeAddress}
        />

        <TaxCard
          defaultErrors={errors}
          isCollapsible={false}
          isEvironmentVisible={false}
          isProfilesTableVisible={false}
          company={company}
          onChange={handleChangeTax}
        />
      </div>

      <Stack.Item fill>
        <Stack spacing="tight">
          <Button
            appearance="default"
            onClick={onPrev}
            icon={ChevronLeftIcon}
          />
          <Button
            spinner={isLoading}
            appearance="primary"
            onClick={handleSubmit}
          >
            Continuar
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default OnboardingView2;
