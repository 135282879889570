import { ITaxProfileAPI } from '../interfaces';

const taxProfileMock: ITaxProfileAPI = {
  InvoiceConfigurationId: '12b42bdc-c7c5-44c8-bed7-ee8b886f966e',
  CompanyId: 'ecef1fab-836f-4a4a-ae09-6ed72d55d682',
  Name: 'Padrão',
  Description: '',
  Configurations: {
    Presencial: 1,
    Natureza: 'VENDA DE MERCADORIAS',
    DefaultFreightCode: 0,
    ConsumidorFinal: 1,
    Serie: 2,
    Finalidade: 1,
    TipoImpressao: 1,
    TipoEmissao: null,
    NotasComplementaresDANFE: null,
    NotasComplementaresFISCO: null,
    CancellationDefaultReason: '',
    PermissionIds: null,
    Item: {
      RejectWithoutEAN: 0,
      ProductName: 'nome_produto',
      TagEANTributavel: '',
      ProductIdentifier: 'sku',
      OrderIdentifier: 'marketplace',
      ProductDefaultCFOP: 'x102',
      ProductDefaultOrigem: '0',
    },
    Tributos: {
      PIS: {
        Operacao: '08',
      },
      COFINS: {
        Operacao: '08',
      },
      ICMS: {
        Operacao: '101',
      },
    },
  },
  CreatedAt: '2023-01-30T22:10:02',
  LastUpdate: '2023-01-30T22:10:02',
};

export default taxProfileMock;
