import { z } from 'zod';

const errorFormSchema = z.object({
  EAN: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  NCM: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  UNIDADE: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CSOSN: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CST: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  CFOP: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
  ICMSOrigem: z.string().trim().min(1, { message: 'Esse campo é obrigatório' }),
});

export default errorFormSchema;
