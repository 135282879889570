import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, InterfaceLabel, Stack } from '@tiendanube/components';
import {
  CheckCircleIcon,
  CloseIcon,
  ExclamationCircleIcon,
} from '@tiendanube/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import {
  AddressCard,
  CompanyCard,
  Page,
  TaxCard,
} from '../../stratus/components';
import { nexoClient } from '../../stratus/services';
import { DigitalCertificateCard } from './components';
import { useCompany } from '../../stratus/context';

function ConfigurationPage() {
  const history = useHistory();
  const location = useLocation();

  const {
    data: company,
    updateCompany,
    updateAddress,
    updateTax,
  } = useCompany();

  const [isOpenByDefault] = useState(
    (location?.state as any)?.showAll ?? false,
  );
  const [isOpenByDefaultTax] = useState(
    (location?.state as any)?.showTax ?? false,
  );

  const labelsHeader: InterfaceLabel[] = [
    {
      id: 'environment',
      appearance: company?.Fiscal.Producao ? 'success' : 'warning',
      icon: company?.Fiscal.Producao ? CheckCircleIcon : ExclamationCircleIcon,
      label: company?.Fiscal.Producao
        ? 'Ambiente de Produção'
        : 'Ambiente de homologação',
    },
  ];

  useEffect(() => {
    navigateHeader(nexoClient, { goTo: '/', text: 'Home' });
  }, []);

  return (
    <Page
      header={
        <Stack justify="space-between">
          <Stack.Item>
            <Page.Header
              title="Configurações"
              subtitle="Atualize as configurações da sua conta"
              labels={labelsHeader}
              topNavigation={{ onClick: history.goBack }}
            />
          </Stack.Item>
          <Button onClick={history.goBack} icon={CloseIcon} />
        </Stack>
      }
    >
      <CompanyCard
        company={company}
        updateCompany={updateCompany}
        isOpenByDefault={isOpenByDefault}
        disableLogoUpload={false}
      />

      <AddressCard
        company={company}
        updateAddress={updateAddress}
        isOpenByDefault={isOpenByDefault}
      />

      <TaxCard
        company={company}
        updateTax={updateTax}
        isOpenByDefault={isOpenByDefault || isOpenByDefaultTax}
      />

      <DigitalCertificateCard />
    </Page>
  );
}

export default ConfigurationPage;
