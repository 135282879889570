import React from 'react';
import { IOrderAPI } from '../../../../stratus/interfaces';
import {
  Button,
  CalloutCard,
  Modal,
  Stack,
  Text,
} from '@tiendanube/components';
import { useOrders } from '../../../../stratus/context';
import { CloseIcon } from '@tiendanube/icons';
import { useHistory } from 'react-router-dom';
import ProductFiscalInfoForm from './ProductFiscalInfoForm';

interface FiscalInfoModalProps {
  order?: IOrderAPI;
  onIssueNFe: (order: IOrderAPI) => void;
  onClose: () => void;
}

function FiscalInfoModal({ onClose, onIssueNFe, order }: FiscalInfoModalProps) {
  const { push } = useHistory();
  const { nfeValidations, validateOrders } = useOrders();

  const orderValidation = nfeValidations?.find(
    (validation) => validation.OrderId === order?.OrderId,
  );
  const isReady = orderValidation?.Errors.length === 0;

  const handleValidateOrder = () => {
    if (!order) {
      return;
    }

    validateOrders([order]);
  };

  if (!order) {
    return null;
  }

  return (
    <Modal
      show={!!order}
      title={`#${order.OrderDisplayId} - ${order.BuyerFirstName} ${order.BuyerLastName}`}
      onDismiss={onClose}
    >
      <Stack column>
        <Text size="highlight">
          Corrija os problemas abaixo para emitir a nota dessa venda
        </Text>

        {orderValidation?.Errors.map((error, index) => {
          if (error.Type === 'COMPANY_ERROR') {
            return (
              <CalloutCard
                key={error.Type + index}
                icon={CloseIcon}
                appearance="danger"
                title="Erro de configuração da empresa"
                subtitle={error.Error}
                onClick={() => push('/configuration', { showAll: true })}
              />
            );
          }
          if (error.Type === 'CONFIGURATION_ERROR') {
            return (
              <CalloutCard
                key={error.Type + index}
                icon={CloseIcon}
                appearance="danger"
                title="Erro de configuração do perfil fiscal"
                subtitle={error.Error}
                onClick={() => push('/configuration', { showTax: true })}
              />
            );
          }
          if (error.Type === 'INVOICE_ERROR') {
            return (
              <CalloutCard
                key={error.Type + index}
                icon={CloseIcon}
                appearance="danger"
                title="Erro ao gerar NFe"
                subtitle={error.Error}
                textLink={{}}
              />
            );
          }
          if (error.Type === 'ORDERDATA_ERROR') {
            return (
              <CalloutCard
                key={error.Type + index}
                icon={CloseIcon}
                appearance="danger"
                title="Erro de dados"
                subtitle={error.Error}
                textLink={{}}
              />
            );
          }
        })}

        {order.Items.map((item) => {
          const productError = orderValidation?.Errors.find(
            (error) => error.ProductId === item.Sku,
          );

          return (
            <ProductFiscalInfoForm
              key={item.Sku}
              product={item}
              error={productError}
            />
          );
        })}

        <Stack justify="center">
          <Button
            appearance={isReady ? 'primary' : 'default'}
            onClick={() =>
              isReady ? onIssueNFe(order) : handleValidateOrder()
            }
          >
            {isReady ? 'Gerar nota' : 'Revalidar nota'}
          </Button>
          {!isReady && (
            <Button appearance="default" onClick={() => onIssueNFe(order)}>
              Re-emitir nota
            </Button>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
}

export default FiscalInfoModal;
