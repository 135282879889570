import { Chip, Input, Stack, Text, Thumbnail } from '@tiendanube/components';

import React, { useState } from 'react';
import { CancelAndSaveButtons } from '../../../../../../stratus/components';
import { useCompany, useToast } from '../../../../../../stratus/context';
import { appConfigData } from '../../../../../../stratus/data';
import { loggerUtil } from '../../../../../../stratus/utils';

interface UploadCertificateProps {
  onClose: () => void;
}

function UploadCertificate({ onClose }: UploadCertificateProps) {
  const { uploadCertificate } = useCompany();
  const { showApiError, showError, showSuccess } = useToast();

  const [isLoading, setLoading] = useState(false);
  const [certificateFile, setCertificateFile] = useState<File>();
  const [certificatePassword, setCertificatePassword] = useState('');

  const handleSave = async () => {
    if (!certificateFile || !certificatePassword) {
      return;
    }

    setLoading(true);

    try {
      await uploadCertificate({ certificateFile, certificatePassword });
      showSuccess('Certificado atualizado com sucesso');
      onClose();
    } catch (error) {
      loggerUtil(error);
      showApiError(
        error,
        'Falha ao atualizar o certificado digital, tente novamente mais tarde',
      );
      setLoading(false);
    }
  };

  const handleSelectFile = (files: File[]) => {
    const [file] = files;

    const allowedTypes = appConfigData.certificateFileType.split(',');
    if (!allowedTypes.includes(file.type)) {
      showError('Tipo de arquivo inválido, favor usar .p12 ou .pfx');
      return;
    }

    if (file.size >= appConfigData.certificateFileSizeLimit) {
      showError(
        'Tamanho do arquivo inválido, favor usar arquivo menor que 15 mb',
      );
      return;
    }

    setCertificateFile(file);
  };

  const handleReset = () => {
    if (isLoading) {
      return;
    }

    setCertificatePassword('');
    setCertificateFile(undefined);
  };

  return (
    <Stack column align="stretch">
      {certificateFile ? (
        <>
          <Chip
            id="certificate"
            label={certificateFile.name}
            onDismiss={handleReset}
          />
          <Input
            name="password"
            label="Senha do certificado"
            placeholder="Senha do certificado"
            onChange={({ value }) => setCertificatePassword(value)}
            value={certificatePassword}
            type="password"
            onSubmit={() => handleSave()}
          />
        </>
      ) : (
        <>
          <Text>
            Selecione seu certificado digital{' '}
            <small>
              (<strong>.p12</strong> ou <strong>.pfx</strong>)
            </small>
          </Text>
          <Thumbnail.File
            aspectRatio="2-1"
            ariaLabel="Certificado Digital"
            accept={appConfigData.certificateFileType}
            width="200px"
            multiple={false}
            onChange={handleSelectFile}
          />
        </>
      )}

      <CancelAndSaveButtons
        saveText="Enviar"
        onCancel={() => onClose()}
        onSave={handleSave}
        cancelText="Cancelar"
        isLoading={isLoading}
      />
    </Stack>
  );
}

export default UploadCertificate;
