import { DataList, Link, Text } from '@tiendanube/components';
import { LabelStatus } from '../../../../../stratus/components';
import { useNfe } from '../../../../../stratus/context';
import { formatDateUtil } from '../../../../../stratus/utils';

const { Row, Cell } = DataList;

function NfeListMobile() {
  const { data: nfes } = useNfe();

  return (
    <DataList>
      {nfes?.map((nfe) => (
        <Row id={nfe.InvoiceId} key={nfe.InvoiceId}>
          <Cell rowTitle trimText width={65}>
            <Text>
              <small>{formatDateUtil(nfe.CreatedAt, 'dd MMM')}</small>
            </Text>
            <Text>
              <small>Pedido: {nfe.OrderId}</small>
            </Text>
            <Link appearance="primary">
              {nfe.RequestData.Buyer.CompanyName}
            </Link>
          </Cell>
          <Cell width={35}>
            <div style={{ textAlign: 'center' }}>
              <Text bold>
                {nfe.RequestData.ValorTotal.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
              <LabelStatus status={nfe.NfeStatus.Name} />
            </div>
          </Cell>
        </Row>
      ))}
    </DataList>
  );
}

export default NfeListMobile;
