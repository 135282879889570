import React from 'react';
import { Text } from '@tiendanube/components';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';

interface OrderCellProductsProps {
  order: IOrderAPI;
}

function OrderCellProducts({ order }: OrderCellProductsProps) {
  return <Text>{order.Items.length}</Text>;
}

export default OrderCellProducts;
