import { BrowserRouter, Switch } from 'react-router-dom';
import NexoSyncRoute from './NexoSyncRoute';
import {
  SignupPage,
  OnboardPage,
  LoadingPage,
  MainPage,
  ConfigurationPage,
  ErrorPage,
  NfesPage,
} from './pages';
import { ProtectedRoute } from './stratus/components';
import { useAuthentication, useCompany, useUser } from './stratus/context';

import './stratus/styles/app.scss';

function App() {
  const { authenticationStatus } = useAuthentication();
  const { hasUser, status: userStatus } = useUser();
  const { hasCompany, status: companyStatus } = useCompany();

  if (
    authenticationStatus === 'loading' ||
    userStatus === 'loading' ||
    companyStatus === 'loading'
  ) {
    return <LoadingPage />;
  }

  if (
    authenticationStatus === 'error' ||
    userStatus === 'error' ||
    companyStatus === 'error'
  ) {
    return <ErrorPage />;
  }

  return (
    <BrowserRouter>
      <NexoSyncRoute>
        <Switch>
          <ProtectedRoute
            isAllowed={hasUser && hasCompany}
            redirect="/signup"
            path="/"
            exact
          >
            <MainPage />
          </ProtectedRoute>
          <ProtectedRoute
            isAllowed={hasUser && hasCompany}
            redirect="/signup"
            path="/configuration"
            exact
          >
            <ConfigurationPage />
          </ProtectedRoute>
          <ProtectedRoute
            isAllowed={hasUser && hasCompany}
            redirect="/signup"
            path="/nfes"
            exact
          >
            <NfesPage />
          </ProtectedRoute>
          <ProtectedRoute
            isAllowed={!hasUser}
            redirect="/welcome"
            path="/signup"
            exact
          >
            <SignupPage />
          </ProtectedRoute>
          <ProtectedRoute
            isAllowed={!hasCompany}
            redirect="/"
            path="/welcome"
            exact
          >
            <OnboardPage />
          </ProtectedRoute>
        </Switch>
      </NexoSyncRoute>
    </BrowserRouter>
  );
}

export default App;
