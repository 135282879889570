function mergeArrays<T>(arr1: T[], arr2: T[], attribute?: keyof T): T[] {
  const mergedArray: T[] = [...arr1];

  for (const obj2 of arr2) {
    const index = mergedArray.findIndex((obj1) =>
      attribute ? obj1[attribute] === obj2[attribute] : obj1 === obj2,
    );
    if (index === -1) {
      mergedArray.push(obj2);
    } else {
      mergedArray[index] = obj2;
    }
  }

  return mergedArray;
}

export default mergeArrays;
