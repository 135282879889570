import { Button, Input, Stack } from '@tiendanube/components';
import { useCompany } from '../../../../../../stratus/context';

interface DigitalCertificateDataProps {
  onChangeClick: () => void;
  onRemoveClick: () => void;
}

function DigitalCertificateData({
  onChangeClick,
  onRemoveClick,
}: DigitalCertificateDataProps) {
  const { data: company } = useCompany();

  return (
    <Stack column align="stretch">
      <Input
        name="valor"
        label="Certificado Digital"
        placeholder="Certificado Digital"
        value={company?.CertificateName ?? ''}
        disabled
      />

      <Input
        name="validade"
        label="Validade"
        placeholder="Validade"
        value={company?.CertificateExpireAt ?? ''}
        disabled
      />

      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button appearance="danger" onClick={onRemoveClick}>
            Remover certificado
          </Button>
        </Stack.Item>
        <Stack.Item>
          <Button appearance="primary" onClick={onChangeClick}>
            Alterar certificado
          </Button>
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

export default DigitalCertificateData;
