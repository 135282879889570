import { InterfaceLabel, Label } from '@tiendanube/components';
import { ClockIcon, CloseIcon, CheckIcon, FlagIcon } from '@tiendanube/icons';
import { INfeStatusName } from '../../interfaces';

interface LabelStatusProps {
  status: INfeStatusName;
}

const labelData: Record<
  INfeStatusName,
  Pick<InterfaceLabel, 'appearance' | 'icon' | 'label'>
> = {
  APPROVED: {
    label: 'Aprovada',
    appearance: 'success',
    icon: CheckIcon,
  },
  CANCELLED: {
    label: 'Cancelada',
    appearance: 'danger',
    icon: CloseIcon,
  },
  REJECTED: {
    label: 'Rejeitada',
    appearance: 'danger',
    icon: CloseIcon,
  },
  REQUESTED: {
    label: 'Requisitada',
    appearance: 'secondary',
    icon: ClockIcon,
  },
  INVALID_DATA: {
    label: 'Dados inválidos',
    appearance: 'danger',
    icon: FlagIcon,
  },
  PRE_REQUEST: {
    label: 'Pré requisitada',
    appearance: 'secondary',
    icon: ClockIcon,
  },
};

function LabelStatus({ status }: LabelStatusProps) {
  return <Label id="id" {...labelData[status]} />;
}

export default LabelStatus;
