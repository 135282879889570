import { InterfaceSelectOptionGroup } from '@tiendanube/components';

const freightCodeOptionsConst: InterfaceSelectOptionGroup[] = [
  { value: '0', label: 'Frete pelo vendedor (padrão)' },
  { value: '1', label: 'Frete pelo comprador' },
  { value: '2', label: 'Frete por terceiros ou marketplace' },
  { value: '9', label: 'Sem frete' },
];

export default freightCodeOptionsConst;
