import { Button, Stack, Text, Title } from '@tiendanube/components';
import { appConfigData } from '../../../../stratus/data';
import { StarIcon } from '@tiendanube/icons';

interface OnboardingSuccessViewProps {
  onFinish: () => void;
}

function OnboardingSuccessView({ onFinish }: OnboardingSuccessViewProps) {
  return (
    <Stack column spacing="base">
      <StarIcon size={46} />
      <Title>Setup concluído!</Title>
      <Text textAlign="center">
        Parabéns, você concluiu o setup do{' '}
        <strong>{appConfigData.appName}</strong>, agora você pode emitir suas
        notas diretamente por aqui. Qualquer duvida pode entrar em contato
        conosco que estamos aqui para ajudar
      </Text>
      <Button appearance="primary" onClick={onFinish}>
        Entrar na plataforma
      </Button>
    </Stack>
  );
}

export default OnboardingSuccessView;
