import { Button, Stack } from '@tiendanube/components';
import { CloseIcon } from '@tiendanube/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { nexoClient } from '../../stratus/services';
import { Page } from '../../stratus/components';

import { NfeList } from './components';

function NfesPage() {
  const history = useHistory();

  useEffect(() => {
    navigateHeader(nexoClient, { goTo: '/', text: 'Home' });
  }, []);

  return (
    <Page
      header={
        <Stack justify="space-between">
          <Stack.Item>
            <Page.Header
              title="Lista de NFe"
              subtitle="Veja aqui suas NFe emitidas"
              topNavigation={{ onClick: history.goBack }}
            />
          </Stack.Item>
          <Button onClick={history.goBack} icon={CloseIcon} />
        </Stack>
      }
    >
      <Stack column align="stretch">
        <NfeList />
      </Stack>
    </Page>
  );
}

export default NfesPage;
