import React from 'react';
import { Text } from '@tiendanube/components';
import { IOrderAPI } from '../../../../../../../stratus/interfaces';

interface OrderCellCustomerProps {
  order: IOrderAPI;
}

function OrderCellCustomer({ order }: OrderCellCustomerProps) {
  return (
    <Text>
      {order.BuyerFirstName} {order.BuyerLastName}
    </Text>
  );
}

export default OrderCellCustomer;
